import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Navbar, PermissionsCheck } from "../../components"
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useGlobal } from '../../contexts';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import produce from 'immer';
import Swal from 'sweetalert2';
import ErrorImage from '../../assets/images/error/404-error-img.svg';

const BASE_URL = process.env.REACT_APP_BASE_API;


export const Ratings = () => {
  const { token } = useGlobal();
  const queryClient = useQueryClient();
  const { data: ratings } = useQuery(
    ['rating'],
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/admin/courses/comments`, { headers });
      const { data } = await response.json();

      return Array.isArray(data) ? data : [];
    },
    { initialData: [] }
  )

  const mutate = useMutation(async ({ ratingId, status }) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    return await fetch(`${BASE_URL}/admin/courses/comments/${ratingId}`, {
      headers,
      method: 'PUT',
      body: JSON.stringify({ status })
    });
  }, {
    onMutate: async ratingId => {
      await queryClient.cancelQueries(['rating']);
      const previousGroup = queryClient.getQueryData(['rating']);
      queryClient.setQueryData(['rating'], previousGroup.filter((item) => item.id !== ratingId));

      return { previousGroup, ratingId }
    },
    onError: (err, ratingId, context) => {
      queryClient.setQueryData(
        ['rating'],
        context.previousGroup
      );

      toast.error('Falha ao aprovar avaliação!');
    },
    onSettled: () => {
      queryClient.invalidateQueries(['rating'])
    },
    onSuccess: () => {
      toast.success('Sucesso ao aprovar avaliação!');
    }
  })


  return (
    <div id="db-wrapper">
      <Navbar />
      <div id="page-content">
        <div class="container-fluid p-4">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                <div class="mb-3 mb-md-0">
                  <h1 class="mb-1 h2 fw-bold">Avaliações Pendentes</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">Cursos</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Avaliações Pendentes
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="card mb-4">
                <div class="table-responsive border-0 overflow-y-hidden">
                  <table class="table mb-0 text-nowrap">
                    <thead class="table-light">
                      <tr>
                        <th class="border-0">User</th>
                        <th class="border-0">Curso</th>
                        <th class="border-0">Comentário</th>
                        <th class="border-0">Avaliação</th>
                        <th class="border-0">Criado em</th>
                        <th class="border-0">Atualizado em</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        ratings.map(({ id, comment, rating, user_id, is_approved, createdAt = '', updatedAt = '', User: { firstname = '', surname = '', profile_picture } = {} }) => (
                          <tr key={id} class="accordion-toggle collapsed" id="accordion1" data-bs-toggle="collapse" data-bs-parent="#accordion1" data-bs-target="#collapseOne">
                            <td class="align-middle border-top-0">
                              <div class="d-lg-flex align-items-center">
                                <div>
                                  <img src={profile_picture || ErrorImage} alt="" class="img-4by3-lg rounded" />
                                </div>
                                <div class="ms-lg-3 mt-2 mt-lg-0">
                                  <h4 class="mb-1 text-primary-hover">
                                    {`${firstname} ${surname}`}
                                  </h4>
                                </div>
                              </div>
                            </td>
                            <td class="align-middle border-top-0">
                              <span dangerouslySetInnerHTML={{ __html: user_id }} />
                            </td>
                            <td class="align-middle border-top-0">
                              <span dangerouslySetInnerHTML={{ __html: comment }} />
                            </td>
                            <td class="align-middle border-top-0">
                              {rating}
                            </td>
                            <td class="align-middle border-top-0">
                              {new Date(createdAt).toLocaleDateString("pt-BR")}
                            </td>
                            <td class="align-middle border-top-0">
                              {new Date(updatedAt).toLocaleDateString("pt-BR")}
                            </td>
                            <td class="align-middle border-top-0">
                              <label
                                onClick={() => mutate.mutate({ ratingId: id, status: 'aprovado' })}
                                class="btn btn-sm btn-icon me-1 btn-success"
                                style={{ width: '2.5rem', height: '2.5rem' }}
                              >
                                <i class="fe fe-check" style={{ fontSize: '1.2rem' }}></i>
                              </label>

                              <label
                                onClick={() => mutate.mutate({ ratingId: id, status: 'reprovado' })}
                                class="btn btn-sm btn-icon btn-danger"
                                style={{ width: '2.5rem', height: '2.5rem' }}
                              >
                                <i class="fe fe-x" style={{ fontSize: '1.2rem' }}></i>
                              </label>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}