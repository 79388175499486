import { Nav } from 'react-bootstrap';
import { Navbar } from "../../components"
import { useState, useEffect } from 'react';
import { useGlobal } from '../../contexts';
import { Tab, useAccordionButton, Accordion, Modal } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import produce from 'immer';

const BASE_URL = process.env.REACT_APP_BASE_API;

function CustomToggle({ children, eventKey, ...props }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <a
      onClick={decoratedOnClick}
      {...props}
    >
      {children}
    </a>
  );
}

export const Sidebar = () => {
  const [currentTab, setCurrentTab] = useState('todos');
  const { token, user } = useGlobal();
  const [menulist, setMenuList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuItem, setMenuItem] = useState({})

  const handleSelectTab = (value) => setCurrentTab(value);
  const isCurrentTab = (tab) => currentTab === tab;

  useEffect(() => {
    const headers = new Headers();
    headers.append("Authorization", token);

    (async () => {
      const response = await fetch(
        `${BASE_URL}/auth/menu`,
        {
          headers,
          method: 'GET',
        }
      );

      if (response) {
        const { data } = await response.json();

        if (data && typeof data === 'object') {
          setMenuList(data);
        }
      }
    })()
  }, [])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const draft = cloneDeep(menulist);
    const old = cloneDeep(menulist);
    const sourceLesson = draft.find((item) => item.order === result.source.index);
    const destinationLesson = draft.find((item) => item.order === result.destination.index);

    sourceLesson.order = result.destination.index;
    destinationLesson.order = result.source.index;

    setMenuList(draft);

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const requests = [
      fetch(
        `${BASE_URL}/admin/menu/${sourceLesson.id}`,
        {
          headers,
          method: 'PUT',
          body: JSON.stringify(sourceLesson)
        }
      ),
      fetch(
        `${BASE_URL}/admin/menu/${destinationLesson.id}`,
        {
          headers,
          method: 'PUT',
          body: JSON.stringify(destinationLesson)
        }
      ),
    ]

    Promise.all(requests)
      .then(() => toast.success('Sucesso ao mover menu!'))
      .catch(() => {
        toast.error('Falha ao mover menu!');
        setMenuList(old)
      })
  }

  const handleEditMenu = (menuId) => () => {
    const menu = menulist.find((item) => item.id === menuId);

    setIsModalOpen(true);
    setMenuItem({ ...menu });
  }

  const handleCloseModalMenu = () => {
    setIsModalOpen(false);
    setMenuItem({});
  }

  const handleUpdateMenu = () => {
    const clonedMenu = cloneDeep(menuItem);

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    fetch(
      `${BASE_URL}/admin/menu/${menuItem.id}`,
      {
        headers,
        method: 'PUT',
        body: JSON.stringify(clonedMenu),
      }
    ).then(() => {
      setMenuList(
        produce((draft) => {
          const index = draft.findIndex((item) => item.id === clonedMenu.id);

          if (index > 0) {
            draft[index] = clonedMenu;
          }
        })
      );

      handleCloseModalMenu();
      toast.success('Sucesso ao editar menu!');
    }).catch(() => {
      toast.error('Falha ao editar menu!');
    })
  }

  return (
    <div id="db-wrapper">
      <Navbar />

      <div id="page-content">
        <div class="container-fluid p-4">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                <div class="mb-3 mb-md-0">
                  <h1 class="mb-1 h2 fw-bold">Courses</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">Configuracao</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Sidebar
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-lg-6 col-md-6 col-6">
              <div class="card rounded-3">
                <div class="card-header border-bottom-0 p-0 bg-white">
                  <div>
                    <Nav class="nav nav-lb-tab" id="tab" role="tablist">
                      <Nav.Item class="nav-item">
                        <Nav.Link
                          active
                          eventKey="todos"
                        >
                          Todos
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                <div>
                  <div class="tab-content" id="tabContent">
                    <Tab.Pane active>
                      <div className="bg-light rounded p-2 mb-4">

                        <DragDropContext onDragEnd={onDragEnd}>

                          <div className="list-group list-group-flush border-top-0" id="courseList">
                            <Droppable id="courseOne" droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {
                                    menulist &&
                                    [...menulist].sort(sortFn).map((item) => (
                                      <Draggable key={item.id} draggableId={`${item.id}`} index={item.order}>
                                        {(provided, snapshot) => (
                                          <Accordion>
                                            <div
                                              className="list-group-item rounded px-3 mb-1"
                                              id="introduction"
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div
                                                className="d-flex align-items-center justify-content-between"
                                              >
                                                <h5 className="mb-0" style={{ flex: 1 }}>
                                                  <a href="#" className="text-inherit">
                                                    <i className="fe fe-menu me-1 text-muted align-middle"></i>
                                                    <span className="align-middle">{item.section}</span>
                                                  </a>
                                                </h5>
                                                <h5 className="mb-0" style={{ flex: 1 }}>
                                                  <a href="#">
                                                    <span className="align-middle">{item.path}</span>
                                                  </a>
                                                </h5>
                                                <div>

                                                  {
                                                    item &&
                                                      item.items &&
                                                      item.items.length ? (
                                                      <>
                                                        <a
                                                          href="#"
                                                          className="me-1 text-inherit"
                                                          data-bs-toggle="tooltip"
                                                          data-placement="top"
                                                          title="Edit"
                                                          onClick={handleEditMenu(item.id)}
                                                        >
                                                          <i className="fe fe-edit fs-6"></i>
                                                        </a>

                                                        <CustomToggle
                                                          href="#"
                                                          eventKey={item.id}
                                                          className="text-inherit"
                                                        >
                                                          <span className="chevron-arrow">
                                                            <i className="fe fe-chevron-down"></i>
                                                          </span>
                                                        </CustomToggle>
                                                      </>

                                                    ) : null
                                                  }
                                                </div>
                                              </div>
                                              {
                                                item &&
                                                  item.items &&
                                                  item.items.length ? (
                                                  <Accordion.Collapse
                                                    eventKey={item.id}
                                                    className="collapse"
                                                  >

                                                    <div className="list-group list-group-flush border-top-0 py-4" id="courseList">
                                                      {[...item.items].sort(sortFn).map((subitem) => (
                                                        <Accordion

                                                        >
                                                          <div
                                                            className="list-group-item rounded px-3 mb-1"
                                                            id="introduction"

                                                          >
                                                            <div
                                                              className="d-flex align-items-center justify-content-between"
                                                            >
                                                              <h5 className="mb-0">
                                                                <a href="#" className="text-inherit">
                                                                  <span className="align-middle">{subitem.label}</span>
                                                                </a>
                                                              </h5>

                                                              <h5 className="mb-0">
                                                                <a href="#">
                                                                  <span className="align-middle">{subitem.path}</span>
                                                                </a>
                                                              </h5>
                                                              <div>
                                                                <a
                                                                  href="#"
                                                                  className="me-1 text-inherit"
                                                                  data-bs-toggle="tooltip"
                                                                  data-placement="top"
                                                                  title="Edit"
                                                                >
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Accordion>
                                                      ))}
                                                    </div>
                                                  </Accordion.Collapse>
                                                ) : null
                                              }

                                            </div>
                                          </Accordion>
                                        )}
                                      </Draggable>
                                    ))
                                  }
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </DragDropContext>
                      </div>
                    </Tab.Pane>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalEditItem
        show={isModalOpen}
        menuItem={menuItem}
        setMenuItem={setMenuItem}
        onClose={handleCloseModalMenu}
        onConfirm={handleUpdateMenu}
        token={token}
      />
    </div>
  )
}

const ModalEditItem = ({ show, menuItem, setMenuItem, onConfirm, onClose, token }) => {
  const title = 'Editar Menu';

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const draft = cloneDeep(menuItem);
    const old = cloneDeep(menuItem);
    const sourceLesson = draft.items.find((item) => item.order === result.source.index);
    const destinationLesson = draft.items.find((item) => item.order === result.destination.index);

    sourceLesson.order = result.destination.index;
    destinationLesson.order = result.source.index;

    setMenuItem(draft);
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/*  <div className="mb-3">
          <label htmlFor="lessonTitle" className="form-label">Titulo</label>
          <input
            value={menuItem.section}
            onChange={(e) => setMenuItem((old) => ({ ...old, section: e.target.value }))}
            className="form-control mb-3"
            id='lessonTitle'
            type="text"
            placeholder="Titulo"
          />
        </div> */}

        {
          menuItem &&
          menuItem.items &&
          menuItem.items.length && (
            <div className="bg-light rounded p-2 mb-4">
              <DragDropContext onDragEnd={onDragEnd}>
                <label htmlFor="lessonTitle" className="form-label">Sub Menus</label>

                <div className="list-group list-group-flush border-top-0" id="courseList">
                  <Droppable id="courseOne" droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {
                          menuItem.items &&
                          [...menuItem.items].sort(sortFn).map((item) => (
                            <Draggable key={`${item.label}-${item.path}`} draggableId={`${item.label}-${item.path}`} index={item.order}>
                              {(provided, snapshot) => (
                                <div
                                  className="list-group-item rounded px-3 mb-1"
                                  id="introduction"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <h5 className="mb-0" style={{ flex: 1 }}>
                                      <a href="#" className="text-inherit">
                                        <i className="fe fe-menu me-1 text-muted align-middle"></i>
                                        <span className="align-middle">{item.label}</span>
                                      </a>
                                    </h5>
                                    <h5 className="mb-0" style={{ flex: 1 }}>
                                      <a href="#">
                                        <span className="align-middle">{item.path}</span>
                                      </a>
                                    </h5>
                                    <div>
                                      <a
                                        href="#"
                                        className="me-1 text-inherit"
                                        data-bs-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                      >
                                      </a>

                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
          )
        }

        <button
          className="btn btn-primary me-2"
          type="Button"
          onClick={onConfirm}
        >
          {title}
        </button>
        <button
          className="btn btn-outline-white"
          onClick={onClose}
        >
          Fechar
        </button>
      </Modal.Body>
    </Modal>
  )
}


const sortFn = (a, b) => {
  if (a.order > b.order) {
    return 1;
  }
  if (a.order < b.order) {
    return -1;
  }

  return 0;
}