import { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Navbar } from "../../../components"
import ErrorImage from '../../../assets/images/error/404-error-img.svg';
import { useQuery } from 'react-query';
import { useGlobal } from '../../../contexts';
import { Link, useParams } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_BASE_API;

export const Category = () => {
  const { categoryId } = useParams();
  const [currentTab, setCurrentTab] = useState('todos');
  const { token, user } = useGlobal();

  const handleSelectTab = (value) => setCurrentTab(value);
  const isCurrentTab = (tab) => currentTab === tab;

  const { data: courses } = useQuery(
    ['courses', { categoryId }],
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/auth/courses/?categories=[${categoryId}]`, { headers });
      const { data } = await response.json();

      return Array.isArray(data.courses) ? data.courses : [];
    },
    { initialData: [] }
  )
  return (
    <div id="db-wrapper">
      <Navbar />

      <div id="page-content">
        <div class="container-fluid p-4">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                <div class="mb-3 mb-md-0">
                  <h1 class="mb-1 h2 fw-bold">Courses</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">Categories</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Courses
                      </li>
                    </ol>
                  </nav>
                </div>
                <div>
                  <Link to="/courses/categories" class="btn btn-primary">Todas as categorias</Link>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="card rounded-3">
                <div class="card-header border-bottom-0 p-0 bg-white">
                  <div>
                    <Nav onSelect={handleSelectTab} class="nav nav-lb-tab" id="tab" role="tablist">
                      <Nav.Item class="nav-item">
                        <Nav.Link
                          active={isCurrentTab('todos')}
                          eventKey="todos"
                        >
                          Todos
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                <div>
                  <div class="tab-content" id="tabContent">
                    <Tab.Pane active={isCurrentTab('todos')}>
                      <div class="table-responsive border-0 overflow-y-hidden">
                        <table class="table mb-0 text-nowrap">
                          <thead class="table-light">
                            <tr>
                              <th scope="col" class="border-0 text-uppercase">
                                Curso
                              </th>
                              <th scope="col" class="border-0 text-uppercase">
                                Instrutor
                              </th>

                              <th scope="col" class="border-0 text-uppercase"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              courses.map(({ id, title, thumbnail, createdAt, author = {} }) => (
                                <tr>
                                  <td>
                                    <Link to={`/courses/${id}`} class="text-inherit">
                                      <div class="d-lg-flex align-items-center">
                                        <div>
                                          <img src={thumbnail || ErrorImage} alt="" class="img-4by3-lg rounded" />
                                        </div>
                                        <div class="ms-lg-3 mt-2 mt-lg-0">
                                          <h4 class="mb-1 text-primary-hover">
                                            {title}
                                          </h4>
                                          <span class="text-inherit">{new Date(createdAt).toLocaleDateString("pt-BR")}</span>
                                        </div>
                                      </div>
                                    </Link>
                                  </td>
                                  <td class="align-middle">
                                    <div class="d-flex align-items-center">
                                      <img src={author ? author.profile_picture : ErrorImage} alt="" class="rounded-circle avatar-xs me-2" />
                                      <h5 class="mb-0">{`${author ? author.firstname : ''} ${author ? author.surname : ''}`}</h5>
                                    </div>
                                  </td>
                                  <td class="align-middle">
                                    <span class="dropdown dropstart">
                                      <a class="text-decoration-none text-muted" href="#" role="button" id="courseDropdown8"
                                        data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                        <i class="fe fe-more-vertical"></i>
                                      </a>
                                      <span class="dropdown-menu" aria-labelledby="courseDropdown8">
                                        <span class="dropdown-header">Settings</span>
                                        <a class="dropdown-item" href="#"><i
                                          class="fe fe-x-circle dropdown-item-icon"></i>Reject with Feedback</a>
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}