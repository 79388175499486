import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Navbar, PermissionsCheck } from "../../components"
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useGlobal } from '../../contexts';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import produce from 'immer';
import Swal from 'sweetalert2'

const BASE_URL = process.env.REACT_APP_BASE_API;


export const Groups = () => {
  const { token, user } = useGlobal();
  const [isModalGroupOpen, setIsModalGroupOpen] = useState(false);
  const [group, setGroup] = useState({ name: '' });
  const [permissions, setPermissions] = useState([]);
  const queryClient = useQueryClient();

  const { data: groups } = useQuery(
    'groups',
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/admin/groups/`, { headers });
      const { data } = await response.json();

      return Array.isArray(data) ? data : [];
    },
    { initialData: [] }
  )

  const insertGroup = async (group) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    await fetch(
      `${BASE_URL}/admin/groups`,
      {
        headers,
        method: 'POST',
        body: JSON.stringify(group)
      }
    )

    return group;
  }

  const { mutate: mutateInsertGroup } = useMutation(insertGroup, {
    onMutate: async newGroup => {
      await queryClient.cancelQueries('groups');
      const previousGroup = queryClient.getQueryData('groups');
      queryClient.setQueryData('groups', [...previousGroup, { ...newGroup.group, createdAt: new Date(), updatedAt: new Date() }]);

      return { previousGroup, newGroup }
    },
    onError: (err, newGroup, context) => {
      queryClient.setQueryData(
        'groups',
        context.previousGroup
      );

      toast.error('Falha ao cadastrar grupo!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('groups')
    },
    onSuccess: () => {
      toast.success('Sucesso ao cadastrar grupo!');
    }
  })

  const deleteGroup = async (groupId) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    return await fetch(
      `${BASE_URL}/admin/groups/${groupId}`,
      {
        headers,
        method: 'DELETE'
      }
    )
  }

  const { mutate: mutateDeleteGroup } = useMutation(deleteGroup, {
    onMutate: async removeId => {
      await queryClient.cancelQueries('groups');
      const previousGroup = queryClient.getQueryData('groups');
      queryClient.setQueryData('groups', previousGroup.filter((group) => group.id !== removeId));

      return { previousGroup, removeId }
    },
    onError: (err, removeId, context) => {
      queryClient.setQueryData(
        'groups',
        context.previousGroup
      );

      toast.error('Falha ao remover grupo!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('groups');
    },
    onSuccess: () => {
      toast.success('Sucesso ao remover grupo!');
    }
  })

  const handleDeleteGroup = (id) => () => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Esta ação não é reversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        mutateDeleteGroup(id);
      }
    })
  }

  const updateGroup = async (group) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    return await fetch(
      `${BASE_URL}/admin/groups/${group.id}`,
      {
        headers,
        method: 'PUT',
        body: JSON.stringify(group)
      }
    )
  }

  const { mutate: mutateUpdateGroup } = useMutation(updateGroup, {
    onMutate: async updated => {
      await queryClient.cancelQueries('groups');
      const previousGroup = queryClient.getQueryData('groups');
      queryClient.setQueryData('groups', produce((draft) => {
        const index = draft.findIndex((group) => group.id === updated.id)

        if (index >= 0) {
          draft[index] = updated;
        }
      }));

      return { previousGroup, updated }
    },
    onError: (err, updated, context) => {
      queryClient.setQueryData(
        'groups',
        context.previousGroup
      );

      toast.error('Falha ao editar grupo!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('groups');
    },
    onSuccess: () => {
      toast.success('Sucesso ao editar grupo!');
    }
  });


  const handleOpenModalGroup = (groupId) => {
    const group = groupId
      ? groups.find((group) => group.id === groupId)
      : { name: '' }

    setGroup(group);

    setIsModalGroupOpen(true);
  }

  const handleCloseModalGroup = () => {
    setGroup({ name: '' })
    setPermissions([])
    setIsModalGroupOpen(false);
  }

  const handleSaveGroup = () => {
    group.id
      ? mutateUpdateGroup(group)
      : mutateInsertGroup({ group, permissions });

    handleCloseModalGroup();
  };

  return (
    <div id="db-wrapper">
      <Navbar />
      <div id="page-content">
        <div class="container-fluid p-4">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                <div class="mb-3 mb-md-0">
                  <h1 class="mb-1 h2 fw-bold">Grupos</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">Users</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Grupos
                      </li>
                    </ol>
                  </nav>
                </div>
                <div>
                  <a href="#" onClick={() => handleOpenModalGroup()} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newCatgory">Adicionar Grupo</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="card mb-4">
                <div class="table-responsive border-0 overflow-y-hidden">
                  <table class="table mb-0 text-nowrap">
                    <thead class="table-light">
                      <tr>
                        <th class="border-0">Grupo</th>
                        <th class="border-0">Criado em</th>
                        <th class="border-0">Atualizado em</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        groups && groups.map(({ id, name, createdAt, updatedAt }) => (
                          <tr class="accordion-toggle collapsed" id="accordion1" data-bs-toggle="collapse" data-bs-parent="#accordion1" data-bs-target="#collapseOne">
                            <td class="align-middle border-top-0">
                              <Link to={`/groups`} class="text-inherit position-relative">
                                <h5 class="mb-0 text-primary-hover">
                                  {name}
                                </h5>
                              </Link>
                            </td>
                            <td class="align-middle border-top-0">
                              {new Date(createdAt).toLocaleDateString("pt-BR")}
                            </td>
                            <td class="align-middle border-top-0">
                              {new Date(updatedAt).toLocaleDateString("pt-BR")}
                            </td>
                            <td class="align-middle">
                              <span class="dropdown dropstart me-2">
                                <a onClick={() => handleOpenModalGroup(id)} class="text-decoration-none text-muted" href="#" role="button" id="courseDropdown8"
                                  data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                  <i class="fe fe-edit"></i>
                                </a>
                              </span>

                              <span class="dropdown dropstart">
                                <a onClick={handleDeleteGroup(id)} class="text-decoration-none text-muted" href="#" role="button" id="courseDropdown8"
                                  data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                  <i class="fe fe-trash-2"></i>
                                </a>
                              </span>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalAddGroup
        show={isModalGroupOpen}
        group={group}
        setGroup={setGroup}
        permissions={permissions}
        setPermissions={setPermissions}
        onClose={handleCloseModalGroup}
        onConfirm={handleSaveGroup}
      />
    </div>
  )
}

const ModalAddGroup = ({ show, group, setGroup, permissions, setPermissions, onConfirm, onClose }) => {
  const title = group && group.id ? 'Editar Grupo' : 'Adicionar Novo Grupo';

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div class="mb-3">
          <label class="form-label" for="title">Nome</label>
          <input
            value={group.name}
            onChange={(e) => setGroup((group) => ({ ...group, name: e.target.value }))}
            type="text"
            class="form-control"
            placeholder="Nome do Grupo"
            id="title"
            required
          />
        </div>

        {
          !group.id && (
            <div class="mb-3">
              <label class="form-label">Permissões</label>
              <PermissionsCheck
                selected={permissions}
                setSelected={setPermissions}
              />
            </div>
          )
        }

        <button
          className="btn btn-primary me-2"
          type="Button"
          onClick={onConfirm}
        >
          {title}
        </button>
        <button
          className="btn btn-outline-white"
          onClick={onClose}
        >
          Fechar
        </button>
      </Modal.Body>
    </Modal>
  )
}