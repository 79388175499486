import Logo from '../../assets/images/organon/logo.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from '../../contexts';

export const NavlinkCollapse = ({ title, icon = '', id, children, disabled, ...props }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleLink = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <a
        href='#'
        className={`nav-link ${!collapsed ? 'collapsed' : ''} ${disabled ? 'disabled' : ''}`}
        data-bs-toggle="collapse"
        aria-expanded={collapsed}
        aria-controls={id}
        onClick={handleToggleLink}
        {...props}
      >
        {icon}
        {title}
      </a>

      <div id={id} className={`collapse ${collapsed ? 'show' : ''}`}>
        {children}
      </div>
    </>
  )
}

export const Navbar = () => {
  const navigate = useNavigate();
  const { signout, token } = useGlobal();

  const handleLogout = () => signout(() => {
    navigate('/login', { replace: true });
  })

  return (
    <nav className="navbar-vertical navbar">
      <div className="nav-scroller">
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="" />
        </a>

        <ul className="navbar-nav flex-column" id="sideNavbar">
          {/* <li className='nav-item'>
            <NavlinkCollapse
              id={`navDashboard`}
              title="Dashboard"
              disabled={false}
              icon={
                <i className="nav-icon fe fe-home me-2"></i>
              }
            >
              <ul className="nav flex-column">
                <li className="nav-item ">
                  <a className="nav-link" href="/">
                    Overview
                  </a>
                </li>
              </ul>
            </NavlinkCollapse>
          </li> */}

          <li className='nav-item'>
            <NavlinkCollapse
              id={`navCourses`}
              title="Cursos"
              disabled={false}
              icon={
                <i className="nav-icon fe fe-book me-2"></i>
              }
            >
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link className="nav-link" to="/courses">
                    Todos os Cursos
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/courses/categories">
                    Todas as Categorias
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/courses/ratings">
                    Avaliações
                  </Link>
                </li>
              </ul>
            </NavlinkCollapse>
          </li>

          <li className='nav-item'>
            <NavlinkCollapse
              id={`navCourses`}
              title="Usuários"
              disabled={false}
              icon={
                <i className="nav-icon fe fe-users me-2"></i>
              }
            >
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link className="nav-link" to="/users">
                    Contas
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/users/roles">
                    Roles
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/users/groups">
                    Grupos
                  </Link>
                </li>
              </ul>
            </NavlinkCollapse>
          </li>

          <li className='nav-item'>
            <NavlinkCollapse
              id={`navCourses`}
              title="Configuração"
              disabled={false}
              icon={
                <i className="nav-icon fe fe-settings me-2"></i>
              }
            >
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link className="nav-link" to="/configuracao/barra-lateral">
                    Menu Lateral
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/configuracao/banners">
                    Banners
                  </Link>
                </li>
              </ul>
            </NavlinkCollapse>
          </li>

          <li className="nav-item">
            <div className="nav-divider"></div>
          </li>

          <li className="nav-item">
            <a href={`${window.location.origin}/home?token=${encodeURIComponent(token)}`} className='nav-link' target='_blank'>
              <i class="nav-icon fe fe-eye me-2"></i> Visualizar Plataforma
            </a>
          </li>

          <li className="nav-item">
            <a onClick={handleLogout} href="#" className='nav-link'>
              <i class="nav-icon fe fe-lock me-2"></i> Sair
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}