import { useEffect, useState } from 'react';
import { Navbar } from '../../../components';
import { useGlobal } from '../../../contexts';
import { useParams, useNavigate } from 'react-router-dom';
import { Tab, useAccordionButton, Accordion, Modal } from 'react-bootstrap';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactPlayer from 'react-player';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import Swal from 'sweetalert2';
import { updateFile } from '../../../helpers';

const BASE_URL = process.env.REACT_APP_BASE_API;

const levels = [
  { value: '', label: 'Selecione o Nível' },
  { value: 'beignners', label: 'Iniciante' },
  { value: 'intermediate', label: 'Intermediário' },
  { value: 'advanced', label: 'Avançado' }
]

function CustomToggle({ children, eventKey, ...props }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <a
      onClick={decoratedOnClick}
      {...props}
    >
      {children}
    </a>
  );
}

/* https://player.vimeo.com/video/76979871?h=8272103f6e */
export const Course = () => {
  const { courseId } = useParams();
  const { token, user } = useGlobal();
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isMaterialLoading, setIsMaterialLoading] = useState(false);
  const [lesson, setLesson] = useState({});
  const [section, setSection] = useState({});
  const [material, setMaterial] = useState({ file: '' });
  const [isModalLessonOpen, setIsModalLessonOpen] = useState(false);
  const [isModalSectionOpen, setIsModalSectionOpen] = useState(false);
  const [isModalMaterialOpen, setIsModalMaterialOpen] = useState(false);
  const [course, setCourse] = useState({
    duration: 0,
    description: '',
    title: '',
    slug: '',
    is_rating_enabled: false,
    content: []
  });

  const [currentTab, setCurrentTab] = useState(0);

  const { data: categories } = useQuery(
    'categories',
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/auth/categories/`, { headers });
      const { data } = await response.json();

      return data.map(({ id, name }) => ({ value: id, label: name }));
    },
    { initialData: [] }
  )

  const { data: groups } = useQuery(
    'groups',
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/auth/groups/`, { headers });
      const { data } = await response.json();

      return data.map(({ id, name }) => ({ value: id, label: name }));
    },
    { initialData: [] }
  )

  const { data: courses } = useQuery(
    ['courses', { category_id: course.category_id, groups: course.course_groups }],
    async () => {
      const headers = new Headers();
      const params = { categories: [course.category_id], groups: course.course_groups }
      const queryParams = jsonToQueryParam(params);
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/auth/courses?${queryParams}`, { headers });
      const { data } = await response.json();

      return typeof data === 'object' ? data.courses.map(({ id, title }) => ({ value: id, label: title })) : [];
    },
    {
      initialData: [],
      enabled: !!course && !!course.category_id && !!course.course_groups
    }
  )

  const { data: users } = useQuery(
    ['users'],
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/admin/users`, { headers });
      const { data } = await response.json();

      return Array.isArray(data)
        ? data
          .filter(({ role: { id } = {} }) => [1, 2].includes(id))
          .map(({ id, firstName, surName }) => ({ value: id, label: `${firstName} ${surName}` }))
        : [];
    },
    {
      initialData: [],
    }
  );

  const { data: benefits } = useQuery(
    ['benefits'],
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/auth/benefits`, { headers });
      const { data } = await response.json();

      return Array.isArray(data) ? data.map(({ id, title }) => ({ value: id, label: title })) : [];
    },
    {
      initialData: [],
    }
  );


  useEffect(() => {
    if (courseId) {
      const headers = new Headers();
      headers.append("Authorization", token);

      (async () => {
        const response = await fetch(
          `${BASE_URL}/auth/courses/${courseId}`,
          {
            headers,
            method: 'GET',
          }
        );

        if (response) {
          const { data } = await response.json();

          if (data && typeof data === 'object') {
            setCourse(data);
          } else {
            navigate('/courses')
          }
        }
      })()
    }
  }, [courseId])

  const handleEditLesson = (sectionId, lessonId) => () => {
    const section = course.content.find((item) => item.id === sectionId);
    const lesson = section.lessons.find((item) => item.id === lessonId);

    setIsModalLessonOpen(true);
    setLesson({ ...lesson, lesson_group_id: sectionId });
  }

  const handleRemoveLesson = (sectionId, lessonId) => () => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    Swal.fire({
      title: 'Você tem certeza?',
      text: "Esta ação não é reversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          `${BASE_URL}/admin/courses/${sectionId}/lessons/${lessonId}`,
          {
            headers,
            method: 'DELETE',
          }
        ).then(() => {
          setCourse(
            produce((draft) => {
              const content = draft.content.find((item) => item.id === sectionId);
              const lessonIndex = content.lessons.findIndex((item) => item.id === lessonId)

              content.lessons.splice(lessonIndex, 1);
            })
          )

          toast.success('Sucesso ao remover aula!');
        }).catch(() => {
          toast.error('Falha ao remover aula!');
        })
      }
    })

  }

  const handleOpenModalLesson = (sectionId) => () => {
    const section = course.content.find((item) => item.id === sectionId);
    const lastLesson = section.lessons.length && section.lessons[section.lessons.length - 1];

    setIsModalLessonOpen(true);
    setLesson({ lesson_group_id: sectionId, order: lastLesson ? lastLesson.order + 1 : 0 });
  }

  const handleEditSection = (sectionId) => () => {
    const section = course.content.find((item) => item.id === sectionId);

    setIsModalSectionOpen(true);
    setSection({ ...section });
  }

  const handleRemoveSection = (sectionId) => () => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    Swal.fire({
      title: 'Você tem certeza?',
      text: "Esta ação não é reversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          `${BASE_URL}/admin/courses/${course.id}/lessons-group/${sectionId}`,
          {
            headers,
            method: 'DELETE',
          }
        ).then(() => {
          setCourse(
            produce((draft) => {
              const contentIndex = draft.content.findIndex((item) => item.id === sectionId);

              draft.content.splice(contentIndex, 1);
            })
          )

          toast.success('Sucesso ao remover grupo de aula!');
        }).catch(() => {
          toast.error('Falha ao remover grupo de aula!');
        })
      }
    })


  }

  const handleOpenModalSection = () => {
    const lastSection = course && course.content && course.content.length && course.content[course.content.length - 1];

    setIsModalSectionOpen(true);
    setSection({ lessons: [], order: lastSection ? lastSection.order + 1 : 0 });
  }

  const handleCloseModalLesson = () => {
    setIsModalLessonOpen(false);
    setLesson({});
  }

  const handleCloseModalSection = () => {
    setIsModalSectionOpen(false);
    setSection({});
  }

  const handleCloseModalMaterial = () => {
    setIsModalMaterialOpen(false);
    setMaterial({ file: '' });
  }

  const handleOpenModalMaterial = (sectionId, lessonId) => () => {
    setIsModalMaterialOpen(true);
    setMaterial({ sectionId, lessonId, file: '' });
  }

  const handleSelectTab = (value) => () => setCurrentTab(value);
  const isCurrentTab = (tab) => currentTab === tab;

  const handleChangeImage = (key) => (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.addEventListener('load', (e) => setCourse((old) => ({ ...old, [key]: e.target.result })))
  }

  const handleChangeCourse = (key) => (e) => setCourse((old) => ({ ...old, [key]: e.target.value }))
  const handleEditKeyCourse = (key) => (value) => setCourse((old) => ({ ...old, [key]: value }))
  const handleSelectKeyCourse = (key) => (selected) => setCourse((old) => ({ ...old, [key]: Array.isArray(selected) ? selected.map(({ value }) => value) : selected.value }))
  const handleCheckCourse = (key) => (e) => setCourse((old) => ({ ...old, [key]: e.target.checked }))

  const handleAddLesson = () => {
    const clonedLesson = cloneDeep(lesson);

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    if (clonedLesson.id) {
      fetch(
        `${BASE_URL}/admin/courses/${clonedLesson.lesson_group_id}/lessons/${clonedLesson.id}`,
        {
          headers,
          method: 'PUT',
          body: JSON.stringify(clonedLesson),
        }
      ).then(() => {
        setCourse(
          produce((draft) => {
            const content = draft.content.find((item) => item.id === clonedLesson.lesson_group_id);
            const lessonIndex = content.lessons.findIndex((item) => item.id === clonedLesson.id)

            content.lessons[lessonIndex] = clonedLesson;
          })
        );

        handleCloseModalLesson();
        toast.success('Sucesso ao editar aula!');
      }).catch(() => {
        toast.error('Falha ao editar aula!');
      })
    } else {
      fetch(
        `${BASE_URL}/admin/courses/${clonedLesson.lesson_group_id}/lessons`,
        {
          headers,
          method: 'POST',
          body: JSON.stringify(clonedLesson)
        }
      ).then(async (response) => {
        if (response) {
          const obj = await response.json();
          const data = obj.data;

          if (data && typeof data === 'object') {
            setCourse(
              produce((draft) => {
                const content = draft.content.find((item) => item.id === Number(data.lesson_group_id));

                content.lessons.push({ ...data, lesson_medias: [] });
              })
            )

            handleCloseModalLesson();
            toast.success('Sucesso ao cadastrar aula!');
          } else {
            toast.error('Falha ao cadastrar aula!');
          }
        }
      })
    }
  }

  const handleAddSection = async () => {
    const { lessons, ...clonedSection } = cloneDeep(section);

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    if (clonedSection.id) {
      fetch(
        `${BASE_URL}/admin/courses/${course.id}/lessons-group/${clonedSection.id}`,
        {
          headers,
          method: 'PUT',
          body: JSON.stringify(clonedSection),
        }
      ).then(() => {
        setCourse(
          produce((draft) => {
            const index = draft.content.findIndex((item) => item.id === clonedSection.id);

            draft.content[index] = { ...clonedSection, lessons }
          })
        )

        handleCloseModalSection();
        toast.success('Sucesso ao editar grupo de aula!');
      }).catch(() => {
        toast.error('Falha ao editar grupo de aula!');
      })

    } else {
      fetch(
        `${BASE_URL}/admin/courses/${course.id}/lessons-group`,
        {
          headers,
          method: 'POST',
          body: JSON.stringify(clonedSection)
        }
      ).then(async (response) => {
        if (response) {
          const obj = await response.json();

          const data = obj.data;

          if (data && typeof data === 'object') {
            setCourse(
              produce((draft) => {
                draft.content
                  ? draft.content.push({ ...data, lessons })
                  : draft.content = [{ ...data, lessons }];
              })
            )

            toast.success('Sucesso ao cadastrar grupo de aula!');
          } else {
            toast.error('Falha ao cadastrar grupo de aula!');
          }
        }

        handleCloseModalSection();
      })
    }
  }

  const handleNextTab = () => {
    setIsLoading(true);
    const { content, certificate, thumbnail, ...clonedCourse } = cloneDeep(course);
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    clonedCourse.duration = clonedCourse.duration * 60;

    if (clonedCourse.id) {
      if (clonedCourse.hasOwnProperty('thumbnail') && !clonedCourse.thumbnail) {
        delete clonedCourse.thumbnail;
      }

      if (clonedCourse.hasOwnProperty('certificate') && !clonedCourse.certificate) {
        delete clonedCourse.certificate;
      }

      delete clonedCourse.related_courses;
      delete clonedCourse.updatedAt;
      delete clonedCourse.createdAt;
      delete clonedCourse.subscribers;
      delete clonedCourse.duration;
      delete clonedCourse.benefits;

      fetch(
        `${BASE_URL}/admin/courses/${clonedCourse.id}`,
        {
          headers,
          method: 'PUT',
          body: JSON.stringify(clonedCourse),
        }
      )
        .then(async () => {
          toast.success('Sucesso ao editar curso!');
          setCurrentTab(1);
          setIsLoading(false);

          const beforeUnloadFn = (e) => {
            e.returnValue = "Upload de arquivos em andamento, deseja mesmo sair?";
          }
          window.addEventListener('beforeunload', beforeUnloadFn);

          let urls = {};

          if (certificate) {
            const { secure_url: certificate_url } = await updateFile(token, certificate, 'certifies');

            urls = { ...urls, certificate_url }
          }

          if (thumbnail) {
            const { secure_url: thumbnail_url } = await updateFile(token, thumbnail, 'thumbnails');

            urls = { ...urls, thumbnail_url }
          }

          fetch(
            `${BASE_URL}/admin/courses/${clonedCourse.id}`,
            {
              headers,
              method: 'PUT',
              body: JSON.stringify(urls)
            }
          )
            .then(() => toast.success('Upload de arquivos finalizado com sucesso!'))
            .finally(() => window.removeEventListener('beforeunload', beforeUnloadFn))
        })
        .catch(() => toast.error('Falha ao editar curso!'));
    } else {
      fetch(
        `${BASE_URL}/admin/courses`,
        {
          headers,
          method: 'POST',
          body: JSON.stringify(clonedCourse)
        }
      ).then(async (response) => {
        if (response) {
          const { data } = await response.json();

          if (data && typeof data === 'object') {
            setCourse({ ...data, content })
            toast.success('Sucesso ao cadastrar curso!');
            setCurrentTab(1);
            setIsLoading(false);

            const beforeUnloadFn = (e) => {
              e.returnValue = "Upload de arquivos em andamento, deseja mesmo sair?";
            }
            window.addEventListener('beforeunload', beforeUnloadFn);

            let urls = {};

            if (certificate) {
              const { secure_url: certificate_url } = await updateFile(token, certificate, 'certifies');

              urls = { ...urls, certificate_url }
            }

            if (thumbnail) {
              const { secure_url: thumbnail_url } = await updateFile(token, thumbnail, 'thumbnails');

              urls = { ...urls, thumbnail_url }
            }

            fetch(
              `${BASE_URL}/admin/courses/${data.id}`,
              {
                headers,
                method: 'PUT',
                body: JSON.stringify(urls)
              }
            )
              .then(() => toast.success('Upload de arquivos finalizado com sucesso!'))
              .finally(() => window.removeEventListener('beforeunload', beforeUnloadFn))
          } else {
            toast.error('Falha ao cadastrar curso!');
            setIsLoading(false);
          }
        }
      })
    }
  }

  const handlePublish = () => navigate('/courses', { replace: true });

  const handlePrevTab = () => setCurrentTab(0);


  const onDragEnd = (sectionId) => (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const draft = cloneDeep(course);
    const old = cloneDeep(course);
    const content = draft.content.find((item) => item.id === sectionId);
    const sourceLesson = content.lessons.find((item) => item.order === result.source.index);
    const destinationLesson = content.lessons.find((item) => item.order === result.destination.index);

    sourceLesson.order = result.destination.index;
    destinationLesson.order = result.source.index;

    setCourse(draft);

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const requests = [
      fetch(
        `${BASE_URL}/admin/courses/${sectionId}/lessons/${sourceLesson.id}`,
        {
          headers,
          method: 'PUT',
          body: JSON.stringify(sourceLesson)
        }
      ),
      fetch(
        `${BASE_URL}/admin/courses/${sectionId}/lessons/${destinationLesson.id}`,
        {
          headers,
          method: 'PUT',
          body: JSON.stringify(destinationLesson)
        }
      ),
    ]

    Promise.all(requests)
      .then(() => toast.success('Sucesso ao mover aula!'))
      .catch(() => {
        toast.error('Falha ao mover aula!');
        setCourse(old)
      })
  }

  const handleDeleteMaterial = (sectionId, lessonId, materialId) => (e) => {
    e.preventDefault();

    const old = cloneDeep(course);
    const headers = new Headers();
    headers.append("Authorization", token);

    Swal.fire({
      title: 'Você tem certeza?',
      text: "Esta ação não é reversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        setCourse(
          produce((draft) => {
            const content = draft.content.find((item) => item.id === sectionId);
            const lesson = content.lessons.find((item) => item.id === lessonId);
            const materialIndex = lesson.lesson_medias.findIndex((item) => item.id === materialId);

            if (materialIndex >= 0) {
              lesson.lesson_medias.splice(materialIndex, 1);
            }
          })
        );

        fetch(
          `${BASE_URL}/admin/courses/materials/${materialId}`,
          {
            headers,
            method: 'DELETE',
          }
        )
          .then(() => {
            toast.success('Sucesso ao deletar material de aula!');
          })
          .catch((e) => {
            setCourse(old);
            toast.error('Falha ao deletar material de aula!');
          })
      }
    })
  }

  const handleAddMaterial = async () => {
    setIsMaterialLoading(true);
    const { sectionId, lessonId, link, file, title } = material;
    let media_url = '';

    if (file) {
      const { secure_url } = await updateFile(token, file, 'materials');
      media_url = secure_url;
    } else if (link) {
      media_url = link;
    }

    if (media_url) {
      const headers = new Headers();
      headers.append("Authorization", token);
      headers.append("Content-Type", "application/json");

      fetch(
        `${BASE_URL}/admin/courses/${lessonId}/materials`,
        {
          headers,
          method: 'POST',
          body: JSON.stringify({
            title,
            teacher_id: user.id,
            media_url
          })
        }
      )
        .then(async (result) => {
          const { data } = await result.json();

          if (typeof data === 'object') {
            setCourse(
              produce((draft) => {
                const content = draft.content.find((item) => item.id === sectionId);
                const lesson = content.lessons.find((item) => item.id === lessonId);

                if (lesson) {
                  lesson.lesson_medias.push(data);
                }
              })
            );

            toast.success('Sucesso ao adicionar material de aula!');
          }
        })
        .catch(() => toast.error('Falha ao adicionar material de aula!'))
        .finally(() => {
          setIsMaterialLoading(false);
          handleCloseModalMaterial();
        })
    }
  }

  return (
    <>
      <div id="db-wrapper">
        <Navbar />

        <div id="page-content">
          <div className="container my-6">
            <div id="courseForm" className="bs-stepper">
              <div className="row">
                <div className="offset-lg-1 col-lg-10 col-md-12 col-12">
                  <div className="bs-stepper-header shadow-sm" role="tablist">
                    <div className="step" data-target="#test-l-1">
                      <button
                        onClick={isCurrentTab(1) ? handlePrevTab : () => { }}
                        type="button"
                        className={`step-trigger ${isCurrentTab(0) ? 'active' : ''}`}
                        role="tab"
                        id="courseFormtrigger1"
                        aria-controls="test-l-1"
                      >
                        <span className="bs-stepper-circle">1</span>
                        <span className="bs-stepper-label">Informações</span>
                      </button>
                    </div>
                    <div className="bs-stepper-line"></div>
                    <div className="step" data-target="#test-l-3">
                      <button
                        onClick={isCurrentTab(0) ? handleNextTab : () => { }}
                        type="button"
                        className={`step-trigger ${isCurrentTab(1) ? 'active' : ''}`}
                        role="tab"
                        id="courseFormtrigger3"
                        aria-controls="test-l-3"
                      >
                        <span className="bs-stepper-circle">2</span>
                        <span className="bs-stepper-label">Aulas</span>
                      </button>
                    </div>
                  </div>

                  <div className="bs-stepper-content mt-5">
                    <form className="tab-content" id="tabContent" onSubmit={e => e.preventDefault()}>
                      <Tab.Pane active={isCurrentTab(0)} id="test-l-1" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="courseFormtrigger1">
                        <div className="card mb-3 ">
                          <div className="card-header border-bottom px-4 py-3">
                            <h4 className="mb-0">Informações</h4>
                          </div>
                          <div className="card-body">
                            <div className="mb-3">
                              <label htmlFor="courseTitle" className="form-label">Titulo</label>
                              <input
                                onChange={handleChangeCourse('title')}
                                value={course.title}
                                id="courseTitle"
                                className="form-control"
                                type="text"
                                placeholder="Titulo"
                              />
                            </div>

                            <div className="mb-3">
                              <label className="form-label">Instrutor</label>
                              <Select
                                value={users.find((group) => group.value === course.author_id)}
                                options={[{ value: '', label: 'Selecione Instrutor' }, ...users]}
                                onChange={handleSelectKeyCourse('author_id')}
                                placeholder='Instrutor'
                              />
                            </div>

                            <div className="mb-3">
                              <label className="form-label">Categoria</label>
                              <Select
                                value={categories.find((group) => group.value === course.category_id)}
                                options={[{ value: '', label: 'Selecione a Categoria' }, ...categories]}
                                onChange={handleSelectKeyCourse('category_id')}
                              />
                            </div>

                            <div className="mb-3">
                              <label className="form-label">Nível</label>
                              <Select
                                value={levels.find((group) => group.value === course.difficulty)}
                                options={levels}
                                onChange={handleSelectKeyCourse('difficulty')}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Grupo</label>
                              <Select
                                value={groups.filter((group) => course.course_groups && course.course_groups.length && course.course_groups.includes(group.value))}
                                options={[{ value: '', label: 'Selecione o Grupo' }, ...groups]}
                                onChange={handleSelectKeyCourse('course_groups')}
                                isMulti
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Relacionados</label>
                              <Select
                                value={courses.filter((item) => course.related_courses && course.related_courses.length && course.related_courses.includes(item.value))}
                                options={[{ value: '', label: 'Selecione Relacionados' }, ...courses]}
                                onChange={handleSelectKeyCourse('related_courses')}
                                placeholder='Cursos Relacionados'
                                isMulti
                              />
                            </div>

                            <div className="mb-3">
                              <label className="form-label">Befícios do curso</label>
                              <Select
                                value={benefits.filter((item) => course.benefits && course.benefits.length && course.benefits.includes(item.value))}
                                options={[{ value: '', label: 'Selecione Benefícios' }, ...benefits]}
                                onChange={handleSelectKeyCourse('benefits')}
                                placeholder='Befícios do curso'
                                isMulti
                              />
                            </div>

                            <div className="mb-3">
                              <label className="form-label">Resumo</label>
                              <ReactQuill
                                theme="snow"
                                value={course.summary || ''}
                                onChange={handleEditKeyCourse('summary')}
                              />
                            </div>

                            <div className="mb-3">
                              <label className="form-label">Descrição</label>
                              <ReactQuill
                                theme="snow"
                                value={course.description}
                                onChange={handleEditKeyCourse('description')}
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="duration" className="form-label">Duração (minutos)</label>
                              <input
                                onChange={handleChangeCourse('duration')}
                                value={course.duration}
                                id="duration"
                                className="form-control"
                                type="number"
                                placeholder="Duração"
                              />
                            </div>

                            <div className="mb-3">
                              <input
                                onChange={handleCheckCourse('is_rating_enabled')}
                                checked={course.is_rating_enabled}
                                id="courseRating"
                                className="form-check-input me-2"
                                type="checkbox"
                              />
                              <label htmlFor="courseRating" className="form-label">Permitir Avaliações</label>
                            </div>

                            <div className="custom-file-container" data-upload-id="courseCoverImg" id="courseCoverImg">
                              <label className="form-label" htmlFor="formThumb">Thumbnail</label>
                              <input onChange={handleChangeImage('thumbnail')} className="form-control" type="file" id="formThumb" />
                              <div className="custom-file-container__image-preview" style={{ backgroundImage: `url(${course.thumbnail || course.thumbnail_url})` }}></div>
                            </div>

                            <div className="custom-file-container" data-upload-id="courseCoverImg" id="courseCoverImg">
                              <label className="form-label" htmlFor="fileCertificate">Certificado</label>
                              <input onChange={handleChangeImage('certificate')} className="form-control" type="file" id="fileCertificate" />
                              <div className="custom-file-container__image-preview" style={{ backgroundImage: `url(${course.certificate || course.certificate_url})` }}></div>
                            </div>

                          </div>
                        </div>
                        <button className="btn btn-primary" onClick={handleNextTab} disabled={isLoading}>
                          Próximo
                        </button>
                      </Tab.Pane>

                      <Tab.Pane active={isCurrentTab(1)} id="test-l-3" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="courseFormtrigger3">
                        <div className="card mb-3  border-0">
                          <div className="card-header border-bottom px-4 py-3">
                            <h4 className="mb-0">Aulas</h4>
                          </div>
                          <div className="card-body ">
                            {
                              course &&
                              course.content &&
                              [...course.content].sort(sortFn).map(({ id, name, lessons }) => (
                                <div key={id} className="bg-light rounded p-2 mb-4">
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <h4>{name}</h4>

                                    <div>
                                      <a
                                        href="#"
                                        className="me-1 text-inherit"
                                        data-bs-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                        onClick={handleEditSection(id)}
                                      >
                                        <i className="fe fe-edit fs-6"></i>
                                      </a>
                                      <a
                                        href="#"
                                        className="me-1 text-inherit"
                                        data-bs-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete"
                                        onClick={handleRemoveSection(id)}
                                      >
                                        <i className="fe fe-trash-2 fs-6"></i>
                                      </a>
                                    </div>
                                  </div>

                                  <DragDropContext onDragEnd={onDragEnd(id)}>

                                    <div className="list-group list-group-flush border-top-0" id="courseList">
                                      <Droppable id="courseOne" droppableId="droppable">
                                        {(provided, snapshot) => (
                                          <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                          >
                                            {
                                              lessons &&
                                              [...lessons].sort(sortFn).map((item) => (
                                                <Draggable key={item.id} draggableId={`${item.id}`} index={item.order}>
                                                  {(provided, snapshot) => (
                                                    <Accordion>
                                                      <div
                                                        className="list-group-item rounded px-3 mb-1"
                                                        id="introduction"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                      >
                                                        <div
                                                          className="d-flex align-items-center justify-content-between"
                                                        >
                                                          <h5 className="mb-0">
                                                            <a href="#" className="text-inherit">
                                                              <i className="fe fe-menu me-1 text-muted align-middle"></i>
                                                              <span className="align-middle">{item.title}</span>
                                                            </a>
                                                          </h5>
                                                          <div>
                                                            <a
                                                              href="#"
                                                              className="me-1 text-inherit"
                                                              data-bs-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Edit"
                                                              onClick={handleEditLesson(id, item.id)}
                                                            >
                                                              <i className="fe fe-edit fs-6"></i>
                                                            </a>
                                                            <a
                                                              href="#"
                                                              className="me-1 text-inherit"
                                                              data-bs-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Delete"
                                                              onClick={handleRemoveLesson(id, item.id)}
                                                            >
                                                              <i className="fe fe-trash-2 fs-6"></i>
                                                            </a>

                                                            <CustomToggle
                                                              href="#"
                                                              eventKey={item.id}
                                                              className="text-inherit"
                                                            >
                                                              <span className="chevron-arrow">
                                                                <i className="fe fe-chevron-down"></i>
                                                              </span>
                                                            </CustomToggle>
                                                          </div>
                                                        </div>
                                                        <Accordion.Collapse
                                                          eventKey={item.id}
                                                          className="collapse"
                                                        >
                                                          <div className="card-body">

                                                            <label>Video</label>
                                                            <ReactPlayer
                                                              className='react-player'
                                                              width='100%'
                                                              url={item.video}
                                                              controls={true}
                                                            />

                                                            <div className='row mt-3'>
                                                              <label>Materiais de aula</label>
                                                              <div className='col'>
                                                                {
                                                                  item.lesson_medias.map((media, index) => (
                                                                    <a
                                                                      href={media.media_url || media.media}
                                                                      target='_blank'
                                                                      className="btn btn-secondary btn-sm m-2"
                                                                    >
                                                                      {media.title}

                                                                      <i onClick={handleDeleteMaterial(id, item.id, media.id)} class="fe fe-trash-2 p-2"></i>
                                                                    </a>
                                                                  ))
                                                                }

                                                                <a onClick={handleOpenModalMaterial(id, item.id)} className="btn btn-secondary btn-sm">Adicionar Material +</a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Accordion.Collapse>
                                                      </div>
                                                    </Accordion>
                                                  )}
                                                </Draggable>
                                              ))
                                            }
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </div>
                                  </DragDropContext>
                                  <a
                                    href="#"
                                    className="btn btn-outline-primary btn-sm mt-3"
                                    onClick={handleOpenModalLesson(id)}
                                  >
                                    Adicionar Aula
                                  </a>
                                </div>
                              ))
                            }

                            <a
                              href="#"
                              className="btn btn-outline-primary btn-sm"
                              onClick={handleOpenModalSection}
                            >
                              Adicionar Grupo
                            </a>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <button className="btn btn-secondary" onClick={handlePrevTab}>
                            Voltar
                          </button>
                          <button disabled={isLoading} className="btn btn-primary" onClick={handlePublish}>
                            Publicar
                          </button>
                        </div>
                      </Tab.Pane>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <ModalAddLecture
        show={isModalLessonOpen}
        lesson={lesson}
        setLesson={setLesson}
        onClose={handleCloseModalLesson}
        onConfirm={handleAddLesson}
      />

      <ModalAddSection
        show={isModalSectionOpen}
        section={section}
        setSection={setSection}
        onClose={handleCloseModalSection}
        onConfirm={handleAddSection}
      />


      <ModalAddMaterial
        show={isModalMaterialOpen}
        material={material}
        setMaterial={setMaterial}
        onClose={handleCloseModalMaterial}
        onConfirm={handleAddMaterial}
        isLoading={isMaterialLoading}
      />
    </>
  )
}

const ModalAddSection = ({ show, section, setSection, onClose, onConfirm }) => {

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Sessão</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <input
          value={section.name}
          onChange={(e) => setSection((old) => ({ ...old, name: e.target.value }))}
          className="form-control mb-3"
          type="text"
          placeholder="Add new section"
        />
        <button
          className="btn btn-primary"
          type="Button"
          onClick={onConfirm}
        >
          Adicionar Sessão
        </button>
        <button
          className="btn btn-outline-white"
          onClick={onClose}
        >
          Fechar
        </button>
      </Modal.Body>
    </Modal>
  )
}

const ModalAddLecture = ({ show, lesson, setLesson, onConfirm, onClose }) => {
  const title = lesson.id
    ? 'Editar Aula'
    : 'Adicionar Nova Aula';

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-3">
          <label htmlFor="lessonTitle" className="form-label">Slug</label>
          <input
            value={lesson.title}
            onChange={(e) => setLesson((old) => ({ ...old, title: e.target.value }))}
            className="form-control mb-3"
            id='lessonTitle'
            type="text"
            placeholder="Titulo"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="lessonVideo" className="form-label">URL do Video</label>
          <input
            value={lesson.video}
            onChange={(e) => setLesson((old) => ({ ...old, video: e.target.value }))}
            className="form-control mb-3"
            id='lessonVideo'
            type="text"
            placeholder="Video"
          />
        </div>

        <div className='mb-3'>
          <ReactPlayer
            className='react-player'
            width='100%'
            url={lesson.video}
            onDuration={(duration) => setLesson((old) => ({ ...old, duration }))}
            controls={true}
          />
        </div>

        <button
          className="btn btn-primary me-2"
          type="Button"
          onClick={onConfirm}
        >
          {title}
        </button>
        <button
          className="btn btn-outline-white"
          onClick={onClose}
        >
          Fechar
        </button>
      </Modal.Body>
    </Modal>
  )
}

const ModalAddMaterial = ({ show, setMaterial, onConfirm, onClose, isLoading }) => {
  const [type, setType] = useState('file');
  const title = 'Adicionar Material';

  const handleChangeFile = (e) => {
    const fileReader = new FileReader();
    const [file] = e.target.files;

    fileReader.addEventListener('load', () => setMaterial((old) => ({ ...old, file: fileReader.result })))
    fileReader.readAsDataURL(file);
  }

  const options = [
    {
      value: 'file',
      label: 'Arquivo'
    },
    {
      value: 'link',
      label: 'Link'
    }
  ]

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-3">
          <label htmlFor="Title" className="form-label">Titulo</label>
          <input
            onChange={(e) => setMaterial((old) => ({ ...old, title: e.target.value }))}
            className="form-control mb-3"
            id='Title'
            type="text"
            placeholder="Title"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Tipo</label>
          <Select
            value={options.find((opt) => opt.value === type)}
            options={options}
            onChange={({ value }) => setType(value)}
          />
        </div>

        {
          type === 'file' ? (
            <div className="mb-3">
              <label htmlFor="lessonVideo" className="form-label">Arquivo</label>
              <input
                onChange={handleChangeFile}
                className="form-control mb-3"
                id='lessonVideo'
                type="file"
                placeholder="Video"
              />
            </div>
          ) : (
            <div className="mb-3">
              <label htmlFor="lessonLink" className="form-label">Link</label>
              <input
                onChange={(e) => setMaterial((old) => ({ ...old, link: e.target.value }))}
                className="form-control mb-3"
                id='lessonLink'
                type="text"
                placeholder="Link"
              />
            </div>
          )
        }

        <button
          className="btn btn-primary me-2"
          type="Button"
          onClick={onConfirm}
          disabled={isLoading}
        >
          {title}
        </button>
        <button
          className="btn btn-outline-white"
          onClick={onClose}
          disabled={isLoading}
        >
          Fechar
        </button>
      </Modal.Body>
    </Modal>
  )
}

const sortFn = (a, b) => {
  if (a.order > b.order) {
    return 1;
  }
  if (a.order < b.order) {
    return -1;
  }

  return 0;
}

const jsonToQueryParam = (params = {}) => {
  return Object.keys(params)
    .map((key) => {
      return (
        encodeURIComponent(key) +
        "=" +
        encodeURIComponent(
          Array.isArray(params[key]) ? JSON.stringify(params[key]) : params[key]
        )
      );
    })
    .join("&");
};

export * from './rating';