import { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Navbar } from "../../components"
import { useQuery } from 'react-query';
import { useGlobal } from '../../contexts';
import { Link } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_BASE_API;

export const Users = () => {
  const [currentTab, setCurrentTab] = useState('todos');
  const { token } = useGlobal();

  const handleSelectTab = (value) => setCurrentTab(value);
  const isCurrentTab = (tab) => currentTab === tab;

  const { data: users } = useQuery(
    'users',
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/admin/users`, { headers });
      const { data } = await response.json();

      return Array.isArray(data) ? data : [];
    },
    { initialData: [] }
  );

  return (
    <div id="db-wrapper">
      <Navbar />

      <div id="page-content">
        <div class="container-fluid p-4">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                <div class="mb-3 mb-md-0">
                  <h1 class="mb-1 h2 fw-bold">Usuários</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">Users</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Todos
                      </li>
                    </ol>
                  </nav>
                </div>
                <div>
                  <Link to="/users/new" class="btn btn-primary">Adicionar Usuário</Link>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="card rounded-3">
                <div class="card-header border-bottom-0 p-0 bg-white">
                  <div>
                    <Nav onSelect={handleSelectTab} class="nav nav-lb-tab" id="tab" role="tablist">
                      <Nav.Item class="nav-item">
                        <Nav.Link
                          active={isCurrentTab('todos')}
                          eventKey="todos"
                        >
                          Todos
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                <div>
                  <div class="tab-content" id="tabContent">
                    <Tab.Pane active={isCurrentTab('todos')}>
                      <div class="table-responsive border-0 overflow-y-hidden">
                        <table class="table mb-0 text-nowrap">
                          <thead class="table-light">
                            <tr>
                              <th scope="col" class="border-0 text-uppercase">
                                Nome
                              </th>
                              <th scope="col" class="border-0 text-uppercase">
                                Grupo
                              </th>
                              <th scope="col" class="border-0 text-uppercase">
                                Role
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              users && users.map(({ id, firstName, surName, Role, Group }) => (
                                <tr>
                                  <td>
                                    <Link to={`/users`} class="text-inherit">
                                      <div class="d-lg-flex align-items-center">
                                        <div class="ms-lg-3 mt-2 mt-lg-0">
                                          <h4 class="mb-1 text-primary-hover">
                                            {`${firstName} ${surName}`}
                                          </h4>
                                        </div>
                                      </div>
                                    </Link>
                                  </td>
                                  <td class="align-middle">
                                    <div class="d-lg-flex align-items-center">
                                      <div class="ms-lg-3 mt-2 mt-lg-0">
                                        <h4 class="mb-1 text-primary-hover">
                                          {Group ? Group.name : '-'}
                                        </h4>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="align-middle">
                                    <div class="d-lg-flex align-items-center">
                                      <div class="ms-lg-3 mt-2 mt-lg-0">
                                        <h4 class="mb-1 text-primary-hover">
                                          {Role ? Role.name : '-'}
                                        </h4>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                  </div>
                </div>
                {/* <div class="card-footer border-top-0">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center mb-0">
                      <li class="page-item disabled">
                        <a class="page-link mx-1 rounded" href="#" tabindex="-1" aria-disabled="true"><i
                          class="mdi mdi-chevron-left"></i></a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link mx-1 rounded" href="#">1</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link mx-1 rounded" href="#">2</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link mx-1 rounded" href="#">3</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link mx-1 rounded" href="#"><i class="mdi mdi-chevron-right"></i></a>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export * from './user';