import { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Navbar } from "../../components"
import Avatar from '../../assets/images/avatar/avatar-1.jpg';
import ErrorImage from '../../assets/images/error/404-error-img.svg';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useGlobal } from '../../contexts';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'

const BASE_URL = process.env.REACT_APP_BASE_API;

export const Courses = () => {
  const queryClient = useQueryClient();
  const [currentTab, setCurrentTab] = useState('todos');
  const { token, user } = useGlobal();

  const handleSelectTab = (value) => setCurrentTab(value);
  const isCurrentTab = (tab) => currentTab === tab;

  const { data: courses } = useQuery(
    'courses',
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/auth/courses/?coursesLimit=0`, { headers });
      const { data } = await response.json();

      return Array.isArray(data.courses) ? data.courses : [];
    },
    { initialData: [] }
  )

  const deleteCourse = async (courseId) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    return await fetch(
      `${BASE_URL}/admin/courses/${courseId}`,
      {
        headers,
        method: 'DELETE'
      }
    )
  }

  const { mutate: mutateDeleteCourse } = useMutation(deleteCourse, {
    onMutate: async removeId => {
      await queryClient.cancelQueries('courses');
      const previousCourses = queryClient.getQueryData('courses');
      queryClient.setQueryData('courses', previousCourses.filter((course) => course.id !== removeId));

      return { previousCourses, removeId }
    },
    onError: (err, removeId, context) => {
      queryClient.setQueryData(
        'courses',
        context.previousCourses
      );

      toast.error('Falha ao remover curso!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('courses');
    },
    onSuccess: () => {
      toast.success('Sucesso ao remover curso!');
    }
  })

  const handleDeleteCourse = (id) => () => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Esta ação não é reversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        mutateDeleteCourse(id);
      }
    })
  }

  return (
    <div id="db-wrapper">
      <Navbar />

      <div id="page-content">
        <div class="container-fluid p-4">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                <div class="mb-3 mb-md-0">
                  <h1 class="mb-1 h2 fw-bold">Courses</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">Courses</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Todos
                      </li>
                    </ol>
                  </nav>
                </div>
                <div>
                  <Link to="/courses/new" class="btn btn-primary">Adicionar Curso</Link>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="card rounded-3">
                <div class="card-header border-bottom-0 p-0 bg-white">
                  <div>
                    <Nav onSelect={handleSelectTab} class="nav nav-lb-tab" id="tab" role="tablist">
                      <Nav.Item class="nav-item">
                        <Nav.Link
                          active={isCurrentTab('todos')}
                          eventKey="todos"
                        >
                          Todos
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                <div>
                  <div class="tab-content" id="tabContent">
                    <Tab.Pane active={isCurrentTab('todos')}>
                      <div class="table-responsive border-0 overflow-y-hidden">
                        <table class="table mb-0 text-nowrap">
                          <thead class="table-light">
                            <tr>
                              <th scope="col" class="border-0 text-uppercase">
                                Curso
                              </th>
                              <th scope="col" class="border-0 text-uppercase">
                                Instrutor
                              </th>

                              <th scope="col" class="border-0 text-uppercase"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              courses && courses.map(({ id, title, thumbnail_url, createdAt, author = {} }) => (
                                <tr>
                                  <td>
                                    <Link to={`/courses/${id}`} class="text-inherit">
                                      <div class="d-lg-flex align-items-center">
                                        <div>
                                          <img src={thumbnail_url || ErrorImage} alt="" class="img-4by3-lg rounded" />
                                        </div>
                                        <div class="ms-lg-3 mt-2 mt-lg-0">
                                          <h4 class="mb-1 text-primary-hover">
                                            {title}
                                          </h4>
                                          <span class="text-inherit">{new Date(createdAt).toLocaleDateString("pt-BR")}</span>
                                        </div>
                                      </div>
                                    </Link>
                                  </td>
                                  <td class="align-middle">
                                    <div class="d-flex align-items-center">
                                      <img src={author ? author.profile_picture : ErrorImage} alt="" class="rounded-circle avatar-xs me-2" />
                                      <h5 class="mb-0">{`${author ? author.firstname : ''} ${author ? author.surname : ''}`}</h5>
                                    </div>
                                  </td>
                                  <td class="align-middle">
                                    <span class="dropdown dropstart">
                                      <Link to={`/courses/${id}/rating`} class="text-decoration-none text-muted me-2" href="#" role="button" id="courseDropdown8"
                                        data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                        <i class="fe fe-bar-chart-2"></i>
                                      </Link>
                                    </span>

                                    <span class="dropdown dropstart">
                                      <a onClick={handleDeleteCourse(id)} class="text-decoration-none text-muted" href="#" role="button" id="courseDropdown8"
                                        data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                        <i class="fe fe-trash-2"></i>
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                  </div>
                </div>
                {/* <div class="card-footer border-top-0">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center mb-0">
                      <li class="page-item disabled">
                        <a class="page-link mx-1 rounded" href="#" tabindex="-1" aria-disabled="true"><i
                          class="mdi mdi-chevron-left"></i></a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link mx-1 rounded" href="#">1</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link mx-1 rounded" href="#">2</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link mx-1 rounded" href="#">3</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link mx-1 rounded" href="#"><i class="mdi mdi-chevron-right"></i></a>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export * from './course';