import { Navbar } from '../../components';
import { useGlobal } from '../../contexts';

export const Dashboard = () => {
  const { isToggled } = useGlobal();

  return (
    <div id="db-wrapper" className={isToggled ? 'toggled' : ''}>
      <Navbar />
    </div>
  )
}