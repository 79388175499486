import { useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { QueryProvider, GlobalContextProvider, RequireAuth } from "./contexts";
import { ToastContainer } from "react-toastify";
import {
  NotFound,
  Dashboard,
  Courses,
  Course,
  Categories,
  Category,
  Login,
  Sidebar,
  Roles,
  Users,
  User,
  Groups,
  CourseRating,
  Ratings,
  Banners
} from './pages';

function App() {
  return (
    <div className="App">
      <QueryProvider>
        <GlobalContextProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />

            <Route
              path="/configuracao/barra-lateral"
              element={
                <RequireAuth>
                  <Sidebar />
                </RequireAuth>
              }
            />

            <Route
              path="/configuracao/banners"
              element={
                <RequireAuth>
                  <Banners />
                </RequireAuth>
              }
            />

            <Route
              path="/courses"
              element={
                <RequireAuth>
                  <Courses />
                </RequireAuth>
              }
            />
            <Route
              path="/courses/categories"
              element={
                <RequireAuth>
                  <Categories />
                </RequireAuth>
              }
            />
            <Route
              path="/courses/categories/:categoryId"
              element={
                <RequireAuth>
                  <Category />
                </RequireAuth>
              }
            />
            <Route
              path="/courses/new"
              element={
                <RequireAuth>
                  <Course />
                </RequireAuth>
              }
            />
            <Route
              path="/courses/:courseId"
              element={
                <RequireAuth>
                  <Course />
                </RequireAuth>
              }
            />

            <Route
              path="/courses/:courseId/rating"
              element={
                <RequireAuth>
                  <CourseRating />
                </RequireAuth>
              }
            />

            <Route
              path="/courses/ratings"
              element={
                <RequireAuth>
                  <Ratings />
                </RequireAuth>
              }
            />

            <Route
              path="/users"
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />
            <Route
              path="/users/new"
              element={
                <RequireAuth>
                  <User />
                </RequireAuth>
              }
            />
            <Route
              path="/users/:userId"
              element={
                <RequireAuth>
                  <User />
                </RequireAuth>
              }
            />
            <Route
              path="/users/roles"
              element={
                <RequireAuth>
                  <Roles />
                </RequireAuth>
              }
            />
            <Route
              path="/users/groups"
              element={
                <RequireAuth>
                  <Groups />
                </RequireAuth>
              }
            />

            <Route
              path="*"
              element={<NotFound />}
            />
          </Routes>
        </GlobalContextProvider>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </QueryProvider>
    </div >
  );
}

export default App;
