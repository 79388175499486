import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Navbar, PermissionsCheck } from "../../components"
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useGlobal } from '../../contexts';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import produce from 'immer';
import Swal from 'sweetalert2'
const BASE_URL = process.env.REACT_APP_BASE_API;


export const Roles = () => {
  const { token, user } = useGlobal();
  const [isModalRoleOpen, setIsModalRoleOpen] = useState(false);
  const [role, setRole] = useState({ name: '' });
  const [permissions, setPermissions] = useState([])
  const queryClient = useQueryClient()

  const { data: roles } = useQuery(
    'roles',
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/admin/roles/`, { headers });
      const { data } = await response.json();

      return Array.isArray(data) ? data : [];
    },
    { initialData: [] }
  )

  const insertRole = async (role) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    await fetch(
      `${BASE_URL}/admin/roles`,
      {
        headers,
        method: 'POST',
        body: JSON.stringify(role)
      }
    )

    return role;
  }

  const { mutate: mutateInsertRole } = useMutation(insertRole, {
    onMutate: async newRole => {
      await queryClient.cancelQueries('roles');
      const previousRole = queryClient.getQueryData('roles');
      queryClient.setQueryData('roles', [...previousRole, { ...newRole.role, createdAt: new Date(), updatedAt: new Date() }]);

      return { previousRole, newRole }
    },
    onError: (err, newRole, context) => {
      queryClient.setQueryData(
        'roles',
        context.previousRole
      );

      toast.error('Falha ao cadastrar role!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('roles')
    },
    onSuccess: () => {
      toast.success('Sucesso ao cadastrar role!');
    }
  })

  const deleteRole = async (courseId) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    return await fetch(
      `${BASE_URL}/admin/roles/${courseId}`,
      {
        headers,
        method: 'DELETE'
      }
    )
  }

  const { mutate: mutateDeleteRole } = useMutation(deleteRole, {
    onMutate: async removeId => {
      await queryClient.cancelQueries('roles');
      const previousRoles = queryClient.getQueryData('roles');
      queryClient.setQueryData('roles', previousRoles.filter((role) => role.id !== removeId));

      return { previousRoles, removeId }
    },
    onError: (err, removeId, context) => {
      queryClient.setQueryData(
        'roles',
        context.previousRoles
      );

      toast.error('Falha ao remover cargo!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('roles');
    },
    onSuccess: () => {
      toast.success('Sucesso ao remover cargo!');
    }
  })

  const handleDeleteRole = (id) => () => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Esta ação não é reversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        mutateDeleteRole(id);
      }
    })
  }

  const updateRole = async (role) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    return await fetch(
      `${BASE_URL}/admin/roles/${role.id}`,
      {
        headers,
        method: 'PUT',
        body: JSON.stringify(role)
      }
    )
  }

  const { mutate: mutateUpdateRole } = useMutation(updateRole, {
    onMutate: async updated => {
      await queryClient.cancelQueries('roles');
      const previousRoles = queryClient.getQueryData('roles');
      queryClient.setQueryData('roles', produce((draft) => {
        const index = draft.findIndex((role) => role.id === updated.id)

        if (index >= 0) {
          draft[index] = updated;
        }
      }));

      return { previousRoles, updated }
    },
    onError: (err, updated, context) => {
      queryClient.setQueryData(
        'roles',
        context.previousRoles
      );

      toast.error('Falha ao editar cargo!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('roles');
    },
    onSuccess: () => {
      toast.success('Sucesso ao editar cargo!');
    }
  });

  const handleOpenModalRole = (roleId) => {
    const role = roleId
      ? roles.find((role) => role.id === roleId)
      : { name: '' }

    setRole(role);

    setIsModalRoleOpen(true);
  }

  const handleCloseModalRole = () => {
    setRole({ name: '' });
    setPermissions([]);

    setIsModalRoleOpen(false);
  }

  const handleSaveRole = () => {
    role.id
      ? mutateUpdateRole(role)
      : mutateInsertRole({ role, permissions });

    handleCloseModalRole();
  };

  return (
    <div id="db-wrapper">
      <Navbar />
      <div id="page-content">
        <div class="container-fluid p-4">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                <div class="mb-3 mb-md-0">
                  <h1 class="mb-1 h2 fw-bold">Cargo</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">Users</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Cargo
                      </li>
                    </ol>
                  </nav>
                </div>
                <div>
                  <a href="#" onClick={() => handleOpenModalRole()} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newCatgory">Adicionar Cargo</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="card mb-4">
                <div class="table-responsive border-0 overflow-y-hidden">
                  <table class="table mb-0 text-nowrap">
                    <thead class="table-light">
                      <tr>
                        <th class="border-0">Cargo</th>
                        <th class="border-0">Criado em</th>
                        <th class="border-0">Atualizado em</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        roles.map(({ id, name, createdAt = '', updatedAt = '' }) => (
                          <tr key={id} class="accordion-toggle collapsed" id="accordion1" data-bs-toggle="collapse" data-bs-parent="#accordion1" data-bs-target="#collapseOne">
                            <td class="align-middle border-top-0">
                              <Link to={`/roles`} class="text-inherit position-relative">
                                <h5 class="mb-0 text-primary-hover">
                                  {name}
                                </h5>
                              </Link>
                            </td>
                            <td class="align-middle border-top-0">
                              {new Date(createdAt).toLocaleDateString("pt-BR")}
                            </td>
                            <td class="align-middle border-top-0">
                              {new Date(updatedAt).toLocaleDateString("pt-BR")}
                            </td>
                            <td class="align-middle">
                              <span class="dropdown dropstart me-2">
                                <a onClick={() => handleOpenModalRole(id)} class="text-decoration-none text-muted" href="#" role="button" id="courseDropdown8"
                                  data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                  <i class="fe fe-edit"></i>
                                </a>
                              </span>

                              {
                                ![1, 2, 3].includes(id) && (
                                  <span class="dropdown dropstart">
                                    <a onClick={handleDeleteRole(id)} class="text-decoration-none text-muted" href="#" role="button" id="courseDropdown8"
                                      data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                      <i class="fe fe-trash-2"></i>
                                    </a>
                                  </span>
                                )
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalAddRole
        show={isModalRoleOpen}
        role={role}
        setRole={setRole}
        permissions={permissions}
        setPermissions={setPermissions}
        onClose={handleCloseModalRole}
        onConfirm={handleSaveRole}
      />
    </div>
  )
}

const ModalAddRole = ({ show, role, setRole, permissions, setPermissions, onConfirm, onClose }) => {
  const title = role && role.id ? 'Editar Cargo' : 'Adicionar Novo Cargo';

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div class="mb-3">
          <label class="form-label" for="title">Nome</label>
          <input
            value={role.name}
            onChange={(e) => setRole((role) => ({ ...role, name: e.target.value }))}
            type="text"
            class="form-control"
            placeholder="Nome do Cargo"
            id="title"
            required
          />
        </div>

        {
          !role.id && (
            <div class="mb-3">
              <label class="form-label">Permissões</label>
              <PermissionsCheck
                selected={permissions}
                setSelected={setPermissions}
              />
            </div>
          )
        }

        <button
          className="btn btn-primary me-2"
          type="Button"
          onClick={onConfirm}
        >
          {title}
        </button>
        <button
          className="btn btn-outline-white"
          onClick={onClose}
        >
          Fechar
        </button>
      </Modal.Body>
    </Modal>
  )
}