import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Navbar } from "../../components"
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useGlobal } from '../../contexts';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import produce from 'immer';
import Swal from 'sweetalert2'
const BASE_URL = process.env.REACT_APP_BASE_API;


export const Categories = () => {
  const { token, user } = useGlobal();
  const [isModalCategoryOpen, setIsModalCategoryOpen] = useState(false);
  const [category, setCategory] = useState({ name: '' });
  const queryClient = useQueryClient()

  const { data: categories } = useQuery(
    'categories',
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/auth/categories/`, { headers });
      const { data } = await response.json();

      return Array.isArray(data) ? data : [];
    },
    { initialData: [] }
  )

  const updateCategory = async (category) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    await fetch(
      `${BASE_URL}/admin/courses/categories/${category.id}`,
      {
        headers,
        method: 'PUT',
        body: JSON.stringify(category),
      }
    )

    return category;
  }

  const insertCategory = async (category) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    await fetch(
      `${BASE_URL}/admin/courses/categories`,
      {
        headers,
        method: 'POST',
        body: JSON.stringify(category)
      }
    )

    return category;
  }

  const { mutate: mutateInsertCategory } = useMutation(insertCategory, {
    onMutate: async newCategory => {
      await queryClient.cancelQueries('categories');
      const previousCategories = queryClient.getQueryData('categories');
      queryClient.setQueryData('categories', [...previousCategories, newCategory]);

      return { previousCategories, newCategory }
    },
    onError: (err, newCategory, context) => {
      queryClient.setQueryData(
        'categories',
        context.previousCategories
      );

      toast.error('Falha ao cadastrar categoria!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('categories')
    },
    onSuccess: () => {
      toast.success('Sucesso ao cadastrar categoria!');
    }
  })

  const { mutate: mutateUpdateCategory } = useMutation(updateCategory, {
    onMutate: async newCategory => {
      await queryClient.cancelQueries('categories')
      const previousCategories = queryClient.getQueryData('categories')

      queryClient.setQueryData('categories', produce((draft) => {
        const index = draft.findIndex((category) => category.id === newCategory.id);

        draft[index] = newCategory;
      }))

      return { previousCategories, newCategory }
    },
    onError: (err, newCategory, context) => {
      queryClient.setQueryData(
        'categories',
        context.previousCategories
      )

      toast.error('Falha ao editar categoria!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('categories')
    },
    onSuccess: () => {
      toast.success('Sucesso ao editar categoria!');
    }
  })

  const handleOpenModalCategory = () => {
    setCategory({ name: '' });
    setIsModalCategoryOpen(true);
  }

  const handleEditModalCategory = (categoryId) => () => {
    setCategory(categories.find((category) => category.id === categoryId))
    setIsModalCategoryOpen(true);
  }

  const handleCloseModalCategory = () => {
    setCategory({ name: '' })
    setIsModalCategoryOpen(false);
  }

  const handleSaveCategory = () => {
    category.id ? mutateUpdateCategory(category) : mutateInsertCategory(category);
    handleCloseModalCategory();
  };

  const deleteCategory = async (categoryId) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    return await fetch(
      `${BASE_URL}/admin/courses/categories/${categoryId}`,
      {
        headers,
        method: 'DELETE'
      }
    )
  }

  const { mutate: mutateDeleteCategory } = useMutation(deleteCategory, {
    onMutate: async removeId => {
      await queryClient.cancelQueries('categories');
      const previousCategories = queryClient.getQueryData('categories');
      queryClient.setQueryData('categories', previousCategories.filter((category) => category.id !== removeId));

      return { previousCategories, removeId }
    },
    onError: (err, removeId, context) => {
      queryClient.setQueryData(
        'categories',
        context.previousCategories
      );

      toast.error('Falha ao remover categoria!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('categories');
    },
    onSuccess: () => {
      toast.success('Sucesso ao remover categoria!');
    }
  })

  const handleDeleteCategory = (id) => () => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Esta ação não é reversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        mutateDeleteCategory(id);
      }
    })
  }

  return (
    <div id="db-wrapper">
      <Navbar />
      <div id="page-content">
        <div class="container-fluid p-4">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                <div class="mb-3 mb-md-0">
                  <h1 class="mb-1 h2 fw-bold">Categorias de Cursos</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">Cursos</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Categorias de Cursos
                      </li>
                    </ol>
                  </nav>
                </div>
                <div>
                  <a href="#" onClick={handleOpenModalCategory} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newCatgory">Adicionar Categoria</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="card mb-4">
                <div class="table-responsive border-0 overflow-y-hidden">
                  <table class="table mb-0 text-nowrap">
                    <thead class="table-light">
                      <tr>
                        <th class="border-0">Categoria</th>
                        <th class="border-0">Criado em</th>
                        <th class="border-0">Atualizado em</th>
                        <th class="border-0"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        categories.map(({ id, name, createdAt, updatedAt }) => (
                          <tr class="accordion-toggle collapsed" id="accordion1" data-bs-toggle="collapse" data-bs-parent="#accordion1" data-bs-target="#collapseOne">
                            <td class="align-middle border-top-0">
                              <Link to={`/courses/categories/${id}`} class="text-inherit position-relative">
                                <h5 class="mb-0 text-primary-hover">
                                  {name}
                                </h5>
                              </Link>
                            </td>
                            <td class="align-middle border-top-0">
                              {new Date(createdAt).toLocaleDateString("pt-BR")}
                            </td>
                            <td class="align-middle border-top-0">
                              {new Date(updatedAt).toLocaleDateString("pt-BR")}
                            </td>
                            <td>
                              <span class="dropdown dropstart me-2">
                                <a
                                  href="#"
                                  className="text-decoration-none text-muted"
                                  data-bs-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                  onClick={handleEditModalCategory(id)}
                                >
                                  <i className="fe fe-edit fs-6"></i>
                                </a>
                              </span>

                              <span class="dropdown dropstart me-2">
                                <a
                                  onClick={handleDeleteCategory(id)}
                                  class="text-decoration-none text-muted"
                                  href="#"
                                  role="button"
                                  id="courseDropdown8"
                                >
                                  <i class="fe fe-trash-2"></i>
                                </a>
                              </span>
                            </td>

                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalAddCategory
        show={isModalCategoryOpen}
        category={category}
        setCategory={setCategory}
        onClose={handleCloseModalCategory}
        onConfirm={handleSaveCategory}
      />
    </div>
  )
}

const ModalAddCategory = ({ show, category, setCategory, onConfirm, onClose }) => {
  const title = category.id
    ? 'Editar Categoria'
    : 'Adicionar Nova Categoria';

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div class="mb-3">
          <label class="form-label" for="title">Nome</label>
          <input
            value={category.name}
            onChange={(e) => setCategory((category) => ({ ...category, name: e.target.value }))}
            type="text"
            class="form-control"
            placeholder="Nome da Categoria"
            id="title"
            required
          />
        </div>

        <button
          className="btn btn-primary me-2"
          type="Button"
          onClick={onConfirm}
        >
          {title}
        </button>
        <button
          className="btn btn-outline-white"
          onClick={onClose}
        >
          Fechar
        </button>
      </Modal.Body>
    </Modal>
  )
}

export * from './category';