import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGlobal } from '../../contexts';
import { toast } from 'react-toastify';
import Logo from '../../assets/images/organon/logo-blue.png'

export const Login = () => {
  const { signin } = useGlobal();
  const navigate = useNavigate();
  const [login, setLogin] = useState({});

  const handleChangeLogin = (key) => (e) => setLogin((old) => ({ ...old, [key]: e.target.value }));
  const handleSubmit = (e) => {
    e.preventDefault();

    signin(login, (token) => {
      if (token) {
        navigate("/courses", { replace: true });
      } else {
        toast.error(
          'Falha ao realizar o login por dados de Usuário e/ou Senha errados.',
          {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    })
  }

  return (
    <div class="container d-flex flex-column">
      <div class="row align-items-center justify-content-center g-0 min-vh-100">
        <div class="col-lg-5 col-md-8 py-8 py-xl-0">
          <div class="card shadow ">
            <div class="card-body p-6">
              <div class="mb-4">
                <a href="#">
                  <img style={{ width: '100%' }} src={Logo} class="mb-4" alt="" />
                </a>
                <h1 class="mb-1 fw-bold">Login</h1>
              </div>
              <form onSubmit={handleSubmit}>
                <div class="mb-3">
                  <label for="email" class="form-label">Email</label>
                  <input
                    value={login.email}
                    onChange={handleChangeLogin('email')}
                    type="email"
                    id="email"
                    class="form-control"
                    name="email"
                    placeholder="Email address here"
                  />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Senha</label>
                  <input
                    value={login.password}
                    onChange={handleChangeLogin('password')}
                    type="password"
                    id="password"
                    class="form-control"
                    name="password"
                    placeholder="**************"
                  />
                </div>

                <div>
                  <div class="d-grid">
                    <button type="submit" class="btn btn-primary ">Sign in</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}