const BASE_URL = process.env.REACT_APP_BASE_API;
const CLOUD_NAME = process.env.REACT_APP_CLOUD_NAME;
const CLOUD_KEY = process.env.REACT_APP_CLOUD_KEY;

export const updateFile = async (token, file, folder) => {
  try {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const response = await fetch(`${BASE_URL}/auth/files/cloudinary-signature`, {
      headers,
      method: 'POST',
      body: JSON.stringify({ folder })
    });

    const result = await response.json();
    const url = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/auto/upload`;

    if (typeof result === 'object') {
      const { signature, timestamp } = result.data;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("api_key", CLOUD_KEY);
      formData.append("timestamp", timestamp);
      formData.append("signature", signature);
      formData.append("folder", folder);

      const response = await fetch(url, {
        method: "POST",
        body: formData
      })

      return response.json();
    }
  } catch (error) {
    console.error(error);

    return error;
  }

}