import { useEffect, useState } from 'react';
import { Navbar } from '../../../components';
import { useGlobal } from '../../../contexts';
import { useParams, useNavigate } from 'react-router-dom';
import { Tab, useAccordionButton, Accordion, Modal } from 'react-bootstrap';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactPlayer from 'react-player';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import Swal from 'sweetalert2';
import { v4 } from 'uuid';

const BASE_URL = process.env.REACT_APP_BASE_API;

const levels = [
  { value: '', label: 'Selecione o Nível' },
  { value: 'beignners', label: 'Iniciante' },
  { value: 'intermediate', label: 'Intermediário' },
  { value: 'advanced', label: 'Avançado' }
]

function CustomToggle({ children, eventKey, ...props }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <a
      onClick={decoratedOnClick}
      {...props}
    >
      {children}
    </a>
  );
}

/* https://player.vimeo.com/video/76979871?h=8272103f6e */
export const User = () => {
  const { userId } = useParams();
  const { token } = useGlobal();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(generateInitialUser);

  const { data } = useQuery(
    'groups',
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/auth/groups/`, { headers });
      const { data } = await response.json();

      return data.map(({ id, name }) => ({ value: id, label: name }));
    },
    { initialData: [] }
  );

  const { data: countries } = useQuery(
    "countries",
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/countries/`, { headers });
      const { data } = await response.json();

      return data.map(({ id, country }) => ({ value: id, label: country }));
    },
    {
      initialData: []
    }
  );

  const { data: states } = useQuery(
    ["states", { country_id: user.country_id }],
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/states/${user.country_id}`, { headers });
      const { data } = await response.json();

      return Array.isArray(data) ? data.map(({ id, state }) => ({ value: id, label: state })) : [];
    },
    {
      initialData: [],
      enabled: !!user.country_id
    }
  );

  const { data: types } = useQuery(
    "roles",
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/admin/roles`, { headers });
      const { data } = await response.json();

      return Array.isArray(data) ? data.map(({ id, name }) => ({ value: id, label: name })) : [];
    },
    {
      initialData: [],
      enabled: !!user.country_id
    }
  );

  const { data: groups } = useQuery(
    "groups",
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/admin/groups`, { headers });
      const { data } = await response.json();

      return Array.isArray(data) ? data.map(({ id, name }) => ({ value: id, label: name })) : [];
    },
    {
      initialData: [],
      enabled: !!user.country_id
    }
  );

  useEffect(() => {
    if (userId) {
      const headers = new Headers();
      headers.append("Authorization", token);

      (async () => {
        const response = await fetch(
          `${BASE_URL}/admin/users/${userId}`,
          {
            headers,
            method: 'GET',
          }
        );

        if (response) {
          const { data } = await response.json();

          if (data && typeof data === 'object') {
            setUser(data);
          } else {
            navigate('/courses')
          }
        }
      })()
    }
  }, [userId])

  const handleCreate = () => {
    setIsLoading(true);

    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    const validation = validateUser(user);

    if (validation.isValid) {
      fetch(
        `${BASE_URL}/admin/users/`,
        {
          headers,
          method: 'POST',
          body: JSON.stringify(user)
        }
      ).then(async (response) => {
        if (response) {
          const { data } = await response.json();

          navigate('/users');
          toast.success('Sucesso ao cadastrar usuário!');
          setIsLoading(false);
        }
      })
    } else {
      toast.error(validation.msg);
      setIsLoading(false);
    }
  }

  const handleChangeUser = (key) => (e) => setUser((old) => ({ ...old, [key]: e.target.value }))
  const handleSelectKeyUser = (key) => (selected) => setUser((old) => ({ ...old, [key]: selected.value }))

  const handleKeyDown = (e) => {
    if (!e.key.match(/^[0-9]$/) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  }


  return (
    <>
      <div id="db-wrapper">
        <Navbar />

        <div id="page-content">
          <div className="container my-6">
            <div id="courseForm" className="bs-stepper">
              <div className="row">
                <div className="offset-lg-1 col-lg-10 col-md-12 col-12">
                  <div className="bs-stepper-content mt-5">
                    <form className="tab-content" id="tabContent" onSubmit={e => e.preventDefault()}>
                      <div className="card mb-3 ">
                        <div className="card-header border-bottom px-4 py-3">
                          <h4 className="mb-0">Novo Usuário</h4>
                        </div>
                        <div className="card-body">
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label htmlFor="courseTitle" className="form-label">Email*</label>
                                <input
                                  onChange={handleChangeUser('email')}
                                  value={user.email}
                                  id="email"
                                  className="form-control"
                                  type="email"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label htmlFor="courseSlug" className="form-label">Senha*</label>
                                <input
                                  value={user.uuid}
                                  id="password"
                                  className="form-control"
                                  type="text"
                                  placeholder="Senha"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label htmlFor="courseTitle" className="form-label">Primeiro Nome*</label>
                                <input
                                  onChange={handleChangeUser('firstname')}
                                  value={user.firstname}
                                  id="firstname"
                                  className="form-control"
                                  type="text"
                                  placeholder="Primeiro Nome"
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label htmlFor="courseSlug" className="form-label">Sobrenome*</label>
                                <input
                                  onChange={handleChangeUser('surname')}
                                  value={user.surname}
                                  id="surname"
                                  className="form-control"
                                  type="text"
                                  placeholder="Sobrenome"
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label htmlFor="courseTitle" className="form-label">Tipo de cliente</label>
                                <input
                                  onChange={handleChangeUser('client_type')}
                                  value={user.client_type}
                                  id="client_type"
                                  className="form-control"
                                  type="text"
                                  placeholder="Tipo"
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label htmlFor="courseSlug" className="form-label">Especialidade</label>
                                <input
                                  onChange={handleChangeUser('speciality')}
                                  value={user.speciality}
                                  id="speciality"
                                  className="form-control"
                                  type="datetime"
                                  placeholder="Especialidade"
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label htmlFor="courseTitle" className="form-label">Telefone*</label>
                                <input
                                  onChange={handleChangeUser('phone')}
                                  onKeyDown={handleKeyDown}
                                  value={user.phone}
                                  id="phone"
                                  className="form-control"
                                  type="text"
                                  placeholder="Telefone"
                                />

                                <small id="emailHelp" class="form-text text-muted">DDD + telefone, sem espaço. Ex: 11987654321</small>

                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label htmlFor="courseSlug" className="form-label">Endereço</label>
                                <input
                                  onChange={handleChangeUser('address_1')}
                                  value={user.address_1}
                                  id="address_1"
                                  className="form-control"
                                  type="datetime"
                                  placeholder="Endereço"
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label className="form-label">País</label>
                                <Select
                                  value={countries.find((country) => country.id === user.country_id)}
                                  options={countries}
                                  onChange={handleSelectKeyUser('country_id')}
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label className="form-label">Estado</label>
                                <Select
                                  value={states.find((state) => state.id === user.state_id)}
                                  options={states}
                                  onChange={handleSelectKeyUser('state_id')}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label className="form-label">Tipo de usuário</label>
                                <Select
                                  value={types.find((state) => state.id === user.role_id)}
                                  options={types}
                                  onChange={handleSelectKeyUser('role_id')}
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className="mb-3">
                                <label className="form-label">Grupo</label>
                                <Select
                                  value={groups.find((state) => state.id === user.group_id)}
                                  options={groups}
                                  onChange={handleSelectKeyUser('group_id')}
                                />
                              </div>
                            </div>

                          </div>

                          <p className='my-2'>
                            * campo obrigatório
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button disabled={isLoading} onClick={handleCreate} className="btn btn-primary">
                          Criar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const generateInitialUser = () => ({
  "firstname": "",
  "surname": "",
  "email": "",
  "uuid": v4(),
  "phone": "",
  "client_type": "",
  "speciality": "",
  "CRT": "",
  "address_1": "",
  "accepted_terms": false,
  "privacy_policy": false,
  "disable_notifications": false,
  "status": "online",
  group_id: 1,
  role_id: 2,
  country_id: 1,
  state_id: 1
})

const validateUser = (user) => {
  let validation = {
    isValid: true,
    msg: ''
  };

  for (const key in user) {
    const field = user[key];

    if (key === 'phone' && field.length < 14) {
      return {
        isValid: false,
        msg: 'São necessários no minímo 14 digitos no campo telefone'
      };
    }

    if (key === 'phone' && field.length > 14) {
      return {
        isValid: false,
        msg: 'São permitidos no máximo 15 digitos no campo telefone'
      };
    }

    if (key === 'uuid' && field.length < 8) {
      return {
        isValid: false,
        msg: 'São necessários no minímo 8 digitos no campo senha'
      };
    }

    if (key === 'surname' && field.length < 3) {
      return {
        isValid: false,
        msg: 'São necessários no minímo 3 digitos no campo segundo nome'
      };
    }

    if (key === 'firstname' && field.length < 3) {
      return {
        isValid: false,
        msg: 'São necessários no minímo 3 digitos no campo primeiro nome'
      };
    }

    if (key === 'email' && !field) {
      return {
        isValid: false,
        msg: 'Campo email é obrigatório'
      };
    }
  }

  return validation;
}