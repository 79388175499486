import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query"
import { useGlobal } from '../../contexts';
import { Accordion } from "react-bootstrap";
import produce from 'immer';
const BASE_URL = process.env.REACT_APP_BASE_API;

export const PermissionsCheck = ({ selected = [], setSelected }) => {
  const { token } = useGlobal();
  console.log({ selected })
  const { data: permissions } = useQuery(
    'permissions',
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const result = await fetch(
        `${BASE_URL}/admin/permissions`,
        {
          headers,
          method: 'GET'
        }
      );

      const { data } = await result.json();

      return Array.isArray(data) ? data : []
    }, {
    initialData: []
  });

  useEffect(() => {
    if (permissions && permissions.length && !selected.length) {
      setSelected(permissions.map((perm) => perm.id));
    }
  }, [permissions])


  const groupedPermission = useMemo(() => {
    return permissions.reduce((old, permission) => {
      const [type, ...groupWords] = permission.name.split(' ');
      const group = groupWords.join(' ');
      const groupIndex = old.findIndex((item) => item.group === group);

      if (groupIndex >= 0) {
        old[groupIndex].permissions.push(permission);
      } else {
        old.push({ group, permissions: [permission] });
      }

      return old;
    }, [])

  }, [permissions])

  const handleCheckGroup = (groupName) => (e) => {
    setSelected(
      produce((draft) => {
        const group = groupedPermission && groupedPermission.find((item) => item.group === groupName);
        const targetPermisssions = group.permissions.map((perm) => perm.id)

        if (e.target.checked) {
          draft.push(...targetPermisssions.filter((permId) => !selected.includes(permId)));
        } else {
          draft = draft.filter((permId) => !targetPermisssions.includes(permId));
        }
      })
    )
  }

  const handleCheckPermission = (id) => (e) => {
    setSelected(
      produce((draft) => {
        if (e.target.checked) {
          draft.push(id);
        } else {
          const index = draft.indexOf(id);

          draft.splice(index, 1);
        }
      })
    )
  }
  console.log({ selected })
  const isGroupChecked = (groupName) => {
    const group = groupedPermission && groupedPermission.find((item) => item.group === groupName);

    return group && group.permissions && group.permissions.every((perm) => selected.includes(perm.id));
  }

  return (
    <Accordion defaultActiveKey="0">
      {
        groupedPermission.map(({ group, permissions = [] }) => (
          <Accordion.Item key={group} eventKey={group}>
            <Accordion.Header>
              <div class="form-check">
                <input
                  checked={isGroupChecked(group)}
                  onChange={handleCheckGroup(group)}
                  onClick={e => e.stopPropagation()}
                  value={group}
                  class="form-check-input"
                  type="checkbox"
                  id={group}
                />
                <label class="form-check-label" for={group}>
                  {group}
                </label>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
                {
                  permissions.map(({ id, name }) => (
                    <div key={id} className="col-md-6">
                      <div class="form-check">
                        <input
                          checked={selected.includes(id)}
                          onChange={handleCheckPermission(id)}
                          value={id}
                          class="form-check-input"
                          type="checkbox"
                          id={name}
                        />
                        <label class="form-check-label" for={name}>
                          {name}
                        </label>
                      </div>
                    </div>
                  ))
                }
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))
      }
    </Accordion>
  )
}