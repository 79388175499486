import { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Navbar } from "../../components"
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useGlobal } from '../../contexts';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { updateFile } from '../../helpers';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const BASE_URL = process.env.REACT_APP_BASE_API;

export const Banners = () => {
  const [image, setImage] = useState();
  const queryClient = useQueryClient();
  const { token } = useGlobal();

  const { data: banners } = useQuery(
    'banners',
    async () => {
      const headers = new Headers();
      headers.append("Authorization", token);

      const response = await fetch(`${BASE_URL}/auth/home/banners`, { headers });
      const { data } = await response.json();

      return Array.isArray(data) ? data : [];
    },
    { initialData: [] }
  );

  const addSlide = (newBanner) => {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append("Authorization", token);
      headers.append("Content-Type", "application/json");

      const fileReader = new FileReader();
      fileReader.addEventListener('load', async () => {
        const { secure_url: banner_url } = await updateFile(token, fileReader.result, 'banners');

        if (banner_url) {
          fetch(
            `${BASE_URL}/admin/home/banners`,
            {
              headers,
              method: 'POST',
              body: JSON.stringify({ banner_url })
            }
          )
            .then(resolve)
            .catch(reject)
        } else {
          reject()
        }
      })

      fileReader.readAsDataURL(newBanner);
    })
  }

  const { mutate: mutateAddSlide } = useMutation(addSlide, {
    onError: (err, newBanner, context) => {
      toast.error('Falha ao adicionar banner!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('banners');
    },
    onSuccess: () => {
      toast.success('Sucesso ao adicionar banner!');
    }
  });

  const removeSlide = async (bannerId) => {
    const headers = new Headers();
    headers.append("Authorization", token);
    headers.append("Content-Type", "application/json");

    return await fetch(
      `${BASE_URL}/admin/home/banners/${bannerId}`,
      {
        headers,
        method: 'DELETE'
      }
    );
  }

  const { mutate: mutateRemoveSlide } = useMutation(removeSlide, {
    onError: () => {
      toast.error('Falha ao remover banner!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('banners');
    },
    onSuccess: () => {
      toast.success('Sucesso ao remover banner!');
    }
  });

  const handleAddSlide = (e) => {
    Swal.fire({
      title: 'Deseja adicionar um novo banner?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Adicionar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        mutateAddSlide(e.target.files[0])
      }
    })
  }

  const handleRemoveSlide = (id) => () => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Esta ação não é reversível!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#d1d1d1',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        mutateRemoveSlide(id);
      }
    })
  }

  const config = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id="db-wrapper">
      <Navbar />

      <div id="page-content">
        <div class="container-fluid p-4">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                <div class="mb-3 mb-md-0">
                  <h1 class="mb-1 h2 fw-bold">Banners</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">Configuração</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Banners
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="card rounded-3">
                <div id="category-slider">
                  <Slider {...config}>
                    {
                      banners.map(({ id, banner_url }) => (
                        <div key={id} className='item'>
                          <img src={banner_url} className='img-fluid' />
                          <button
                            onClick={handleRemoveSlide(id)}
                            style={{ maxWidth: 240, position: 'absolute', top: 0, left: 0 }}
                            className='btn btn-danger'
                          >
                            Remover
                          </button>
                        </div>
                      ))
                    }

                    {
                      banners.length <= 10 && (
                        <div key={'add'} className='item'>
                          <div className='add'>
                            <label
                              style={{ maxWidth: 240 }}
                              className='btn btn-primary'
                            >
                              Adicionar

                              <input onChange={handleAddSlide} type='file' className='d-none' />
                            </label>
                          </div>
                        </div>
                      )
                    }
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}